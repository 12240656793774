import React from 'react';
import { ButtonStyled } from './Button.styled';

const Button = ({ handleSubmit = () => {}, title = '', type, name }) => {
  return (
    <ButtonStyled type={type} name={name} onClick={handleSubmit}>
      {title}
    </ButtonStyled>
  );
};

export default Button;
