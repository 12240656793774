import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';

export const ContainerWrapper = styled.div`
  padding: 16px;
  text-align: center;
  position: relative;
  .inputFlexStyled {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    padding-top: 16px;
    width: 100%;
  }

  .checkboxWithLabelFlexStyled {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-top: 16px;
    width: 100%;
    flex-wrap: wrap;
  }
  .checkboxHeaderIsShowOnSMDevice {
    display: none;
  }
  @media ${DEVICES.sMobile} {
    max-width: 100%;
  }
  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    padding-top: 24px;
    .inputFlexStyled {
      display: flex;
      width: 100%;
      gap: 16px;
      padding-top: 24px;
    }
    .checkboxWithLabelFlexStyled {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
      padding-top: 16px;
      margin-top: 0px;
    }
    .checkboxWithLabelFlexStyled .checkboxHeaderIsShowOnSMDevice {
      display: block;
      padding-top: 14px;
    }
    .checkboxWithLabelFlexStyled .checkboxHeaderIsShowOnSMDevice h3 {
      color: #010915;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.16px;
      text-align: start;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 6px;
    }
  }
`;

export const FormContainer = styled.div`
  .customInputStyle1 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle2 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle3 {
    width: calc(33% - 10px);
    text-align: start;
  }
  .customInputStyle4 {
    width: calc(33% - 10px);
    text-align: start;
  }
  .customInputStyle5 {
    width: calc(33% - 10px);
    text-align: start;
  }

  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    .customInputStyle1 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle2 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle5 {
      width: 100%;
      text-align: start;
    }
  }
  @media ${DEVICES.tablet} {
    .customInputStyle1 {
      width: calc(50% - 12px);
      text-align: start;
    }
    .customInputStyle2 {
      width: calc(50% - 12px);
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(50% - 12px);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(50% - 12px);
      text-align: start;
    }
    .customInputStyle5 {
      width: 100%;
      text-align: start;
    }
  }
  @media ${DEVICES.sMobile} {
    .customInputStyle1 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle2 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle5 {
      width: 100%;
      text-align: start;
    }
  }
`;

export const CheckboxContainer = styled.div`
  padding: 8px;
  background-color: #ebe0d033;
  border-radius: 3px;
  border: 1px solid #fdfcfb;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 50px;
  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    border: ${(props) =>
      props.checked ? '1px solid #161c2d' : '1px solid #C7C7C7'};
    border-radius: 5px;
    background: rgba(235, 224, 208, 0.2);
    justify-content: space-between;
    height: auto;
    display: block;
    padding: 12px;
    min-height: 100%;
  }
`;

export const CheckboxWithNestedCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 8px);
  .subOptionsHeaderStyled {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    margin-top: 0px;
    width: 100%;
    #subOptionalStyled {
      display: none;
    }
  }
`;

export const NestedCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
  gap: 10px;
  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
    padding: 12px;
    border: 1px solid #c7c7c7;
    border-top: 0px;
    margin-top: -3px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
export const TabSectionStyle = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(244, 243, 243);
  gap: 30px;
  width: 100%;
  margin-bottom: 4px;
`;
export const NestedInputContainer = styled.div`
  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    padding: 12px;
    width: 100%;
    border: 1px solid #c7c7c7;
    border-top: 0px;
    margin-top: -3px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px;
  @media ${DEVICES.sMobile} {
    margin-top: 0px;
  }
  @media ${DEVICES.mobile}, ${DEVICES.tablet} {
    margin-top: 0px;
  }
`;

export const ErrorMsgStyled = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: red;
  text-align: start;
`;
