export const config = {
  // local
  // 'chatbotbackendapi': 'http://localhost:5017/webhook',

  // working stage credential
  // client_id: 'chat-bot-ui',
  // client_secret: 'Zq2Ppy9tz34aKORLhbBYYtfltXEYiXNC',
  // username: 'Testuserchatbot@xpressbees.com',
  // password: 'Test@123456789',
  // chatbotbackendapi: 'https://stage-chatbot.xbees.in/webhook',
  // auth_url: 'https://stage-auth.xbees.in/',
  // realms: 'xpressbees',
  grant_type: 'password',
};
