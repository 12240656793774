import { stateList } from './constant';

// Form Constant Value
export const formConstant = {
  FULL_NAME: 'fullName',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL_ADDRESS: 'email',
  ADDRESS: 'address',
  PIN_CODE: 'pinCode',
  CITY: 'city',
  STATE: 'state',
  ZONE: 'zone',
};

export const formConfig = (t) => {
  return {
    inputs: [
      {
        name: formConstant.FULL_NAME,
        placeholder: t('app.FULL_NAME'),
        customStyle: 'customInputStyle1',
        validations: {
          required: true,
          minLength: 3,
        },
        type: 'text',
      },
      {
        name: formConstant.PHONE_NUMBER,
        placeholder: t('app.PHONE_NUMBER'),
        customStyle: 'customInputStyle2',
        validations: {
          required: true,
          pattern: /^[0-9]{10}$/g,
        },
        type: 'tel',
      },
      {
        name: formConstant.EMAIL_ADDRESS,
        placeholder: t('app.EMAIL_ID'),
        customStyle: 'customInputStyle3',
        validations: {
          required: true,
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/g,
        },
        type: 'text',
      },
      {
        name: formConstant.ADDRESS,
        placeholder: t('app.ADDRESS'),
        customStyle: 'customInputStyle4',
        validations: {
          required: true,
          minLength: 3,
        },
        type: 'text',
      },
      {
        name: formConstant.PIN_CODE,
        placeholder: t('app.PIN_CODE'),
        customStyle: 'customInputStyle5',
        validations: {
          required: true,
        },
        type: 'text',
      },
      {
        name: formConstant.CITY,
        placeholder: t('app.CITY'),
        customStyle: 'customInputStyle6',
        validations: {
          required: true,
          minLength: 2,
        },
        type: 'text',
      },
      {
        name: formConstant.STATE,
        placeholder: t('app.STATE'),
        customStyle: 'customInputStyle7',
        validations: {
          required: true,
        },
        type: 'dropdown',
        options: stateList,
      },
    ],
  };
};
// REGEX Constant
export const regex = {
  REMOVE_WHITE_SPACE: /\s{2,}/g,
};
