import { stateList } from '../../utils/utils';

// Form Constant Value
export const formConstant = {
  FULL_NAME: 'fullName',
  BUSINESS_REGISTERED_NAME: 'businessName',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL_ADDRESS: 'email',
  CITY_STATE: 'cityState',
  SMALL_SIZE_SHIPMENTS: 'isSmallSizeShipments',
  SHIPMENTS_MONTH: 'subOptionsLabel',
  SHIPMENTS: 'subOptions',
  SMALL_SIZE_OPTION_1: 'isSmallSizeOption1',
  SMALL_SIZE_OPTION_2: 'isSmallSizeOption2',
  SMALL_SIZE_OPTION_3: 'isSmallSizeOption3',
  PART_TRUCK_LOAD: 'isPartTruckLoad',
  PART_TRUCK_LOAD_VALUE: 'partTruckLoadValue',
  FULL_TRUCK_LOAD_VALUE: 'fullTruckLoadValue',
  FULL_TRUCK_LOAD: 'isFullTruckLoad',
  CROSS_BORDER_INTERNATIONAL: 'isCrossBorder',
  CROSS_BORDER_VALUE: 'crossBorderValue',
  IS_SMALL_SIZE_OPTION: 'isSmallSizeOption',
};

export const formConfig = (t) => {
  return {
    inputs: [
      {
        name: formConstant.FULL_NAME,
        placeholder: t('app.FULL_NAME'),
        customStyle: 'customInputStyle1',
        validations: {
          required: true,
          minLength: 3,
        },
        type: 'text',
      },
      {
        name: formConstant.BUSINESS_REGISTERED_NAME,
        placeholder: t('app.BUSINESS_REGISTERED_NAME'),
        customStyle: 'customInputStyle2',
        validations: {
          required: true,
          minLength: 3,
        },
        type: 'text',
      },
      {
        name: formConstant.PHONE_NUMBER,
        placeholder: t('app.PHONE_NUMBER'),
        customStyle: 'customInputStyle3',
        validations: {
          required: true,
          pattern: /^[0-9]{10}$/g,
        },
        type: 'tel',
      },
      {
        name: formConstant.EMAIL_ADDRESS,
        placeholder: t('app.EMAIL_ID'),
        customStyle: 'customInputStyle4',
        validations: {
          required: true,
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/g,
        },
        type: 'text',
      },
      {
        name: formConstant.CITY_STATE,
        placeholder: t('app.STATE'),
        customStyle: 'customInputStyle5',
        validations: {
          required: true,
        },
        options: stateList,
        type: 'dropdown',
      },
    ],

    checkboxes: [
      {
        name: formConstant.SMALL_SIZE_SHIPMENTS,
        label: t('app.SMALL_SIZE_SHIPMENTS'),
        subOptionsLabel: t('app.SHIPMENTS_MONTH'),
        subSmallSizeShipmentWeightTabs: ['Upto 5kg', 'Above 5kg'],
        subOptions: [
          {
            name: formConstant.SMALL_SIZE_OPTION_1,
            label: t('app.SHIPMENTS', { value: '<500' }),
            value: '<500',
          },
          {
            name: formConstant.SMALL_SIZE_OPTION_2,
            label: t('app.SHIPMENTS', { value: '>500 & <5000' }),
            value: '>500 & <5000',
          },
          {
            name: formConstant.SMALL_SIZE_OPTION_3,
            label: t('app.SHIPMENTS', { value: '>5000' }),
            value: '>5000',
          },
        ],
      },
      {
        name: formConstant.PART_TRUCK_LOAD,
        label: t('app.PART_TRUCK_LOAD'),
        subOptionsLabel: t('app.TONS_MONTH'),
        subOptions: [
          {
            name: formConstant.PART_TRUCK_LOAD_VALUE,
            placeholder: t('app.TYPE_A_NUMERIC'),
            type: 'text',
            validations: {
              required: true,
              pattern: regex.NUMBER,
            },
          },
        ],
      },
      {
        name: formConstant.FULL_TRUCK_LOAD,
        label: t('app.FULL_TRUCK_LOAD'),
        subOptionsLabel: t('app.TRUCKS_MONTH'),
        subOptions: [
          {
            name: formConstant.FULL_TRUCK_LOAD_VALUE,
            placeholder: t('app.TYPE_A_NUMERIC'),
            type: 'text',
            validations: {
              required: true,
              pattern: regex.NUMBER,
            },
          },
        ],
      },
      {
        name: formConstant.CROSS_BORDER_INTERNATIONAL,
        label: t('app.CROSS_BORDER_INTERNATIONAL'),
        subOptionsLabel: t('app.SHIPMENTS_MONTH'),
        subOptions: [
          {
            name: formConstant.CROSS_BORDER_VALUE,
            placeholder: t('app.TYPE_A_NUMERIC'),
            type: 'text',
            validations: {
              required: true,
              pattern: regex.NUMBER,
            },
          },
        ],
      },
    ],
  };
};

export const checkboxValueMap = {
  isPartTruckLoad: 'partTruckLoadValue',
  isFullTruckLoad: 'fullTruckLoadValue',
  isCrossBorder: 'crossBorderValue',
};

export const checkboxMap = {
  isSmallSizeShipments: 'isSmallSizeShipments',
  isPartTruckLoad: 'isPartTruckLoad',
  isFullTruckLoad: 'isFullTruckLoad',
  isCrossBorder: 'isCrossBorder',
};

// REGEX Constant
export const regex = {
  REMOVE_WHITE_SPACE: /\s{2,}/g,
  NUMBER: /[^0-9]/g,
};
