import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import { PAGE_URL } from '../config/appConfig';
import useAnalytics from '../hooks/useAnalytics';
import usePrevious from '../hooks/usePrevious';

const TrackShipmentPage = lazy(() =>
  import('../pages/TrackShipmentPage/TrackShipment')
);
const HomePage = lazy(() => import('../pages/HomePage/HomePage'));
const BlogsPage = lazy(() => import('../pages/Blogs/BlogsPage'));
const BlogListing = lazy(() => import('../pages/Blogs/BlogListing'));
const BlogDetails = lazy(() => import('../pages/Blogs/BlogDetails'));
const ServicePage = lazy(() => import('../pages/ServicePage/ServicePage'));
const CareersPage = lazy(() => import('../pages/CareersPage/CareersPage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage/AboutUsPage'));
const NewsListing = lazy(() => import('../pages/NewsPages/NewsListing'));
const NewsDetails = lazy(() => import('../pages/NewsPages/NewsDetails'));
const ContactUsPage = lazy(() =>
  import('../pages/ContactUsPage/ContactUsPage')
);
const PrivacyPolicy = lazy(() =>
  import('../pages/PrivacyPolicy/PrivacyPolicy')
);
const VendorPolicy = lazy(() => import('../pages/VendorPolicy/VendorPolicy'));
const AntiCorruption = lazy(() =>
  import('../pages/VendorPolicy/AntiCorruption/AntiCorruption')
);
const CodeOfConduct = lazy(() =>
  import('../pages/VendorPolicy/CodeOfConduct/CodeOfConduct')
);
const OldTracking = lazy(() => import('../pages/OldTracking/OldTracking'));
const TermsOfUse = lazy(() => import('../pages/TermsOfUse/TermsOfUse'));
const TrackShipment = lazy(() =>
  import('../pages/TrackShipmentPage/TrackShipment')
);
const TestPage = lazy(() => import('../pages/TestPage/TestPage'));
const PartnerPage = lazy(() => import('../pages/Partner/Partner'));

const AppRoutes = () => {
  const { trackPageView } = useAnalytics();
  const location = useLocation();
  const previousLocation = usePrevious(location.pathname);

  useEffect(() => {
    if (previousLocation) {
      if (previousLocation !== location.pathname) {
        const pagePath = `${
          location.pathname.length === 1
            ? 'home'
            : location.pathname.substring(1)
        }`;
        const page = `${pagePath}${location.hash}`;
        const title = `${pagePath}${location.hash} page`;
        const payload = {
          page: page,
          title: title,
        };
        trackPageView(payload);
      }
    }
  }, [location]);

  return (
    <Suspense fallback={<p>loading...</p>}>
      <Layout id='xb-website'>
        <Routes>
          <Route index path={PAGE_URL.HOMEPAGE} element={<HomePage />} />
          <Route index path='*' element={<Navigate to={PAGE_URL.HOMEPAGE} />} />
          <Route index path={PAGE_URL.CAREER_PAGE} element={<CareersPage />} />
          <Route path={PAGE_URL.ABOUT_US} element={<AboutUsPage />} />
          <Route path={PAGE_URL.NEWSPAGE_ALL} element={<NewsListing />} />
          <Route path={PAGE_URL.NEWSPAGE_SINGLE} element={<NewsDetails />} />
          <Route index path={PAGE_URL.CONTACT_US} element={<ContactUsPage />} />
          <Route path={PAGE_URL.MEDIA_BLOGS_ALL} element={<BlogListing />} />
          <Route path={PAGE_URL.MEDIA_BLOGS_SINGLE} element={<BlogDetails />} />
          <Route path={PAGE_URL.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={PAGE_URL.VENDOR_POLICY} element={<VendorPolicy />} />
          <Route path={PAGE_URL.CODE_OF_CONDUCT} element={<CodeOfConduct />} />
          <Route path={PAGE_URL.ANTI_CORRUPTION} element={<AntiCorruption />} />
          <Route path={PAGE_URL.SERVICES_B2C} element={<ServicePage />} />
          <Route path={PAGE_URL.SERVICES_B2B} element={<ServicePage />} />
          <Route path={PAGE_URL.SERVICES_CBL} element={<ServicePage />} />
          <Route path={PAGE_URL.SERVICES_TPL} element={<ServicePage />} />
          <Route
            path={PAGE_URL.SHIPMENT_TRACKING}
            element={<TrackShipmentPage />}
          />
          <Route path={PAGE_URL.TERMS_OF_USE} element={<TermsOfUse />} />
          <Route path={PAGE_URL.TRACKING} element={<TrackShipment />} />
          {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && (
            <Route path={PAGE_URL.TEST} element={<TestPage />} />
          )}
          <Route
            path={PAGE_URL.OLD_WEBSITE_TRACKING_PAGE}
            element={<OldTracking />}
          />
          <Route path={PAGE_URL.PARTNER} element={<PartnerPage />} />
        </Routes>
      </Layout>
    </Suspense>
  );
};

export default AppRoutes;
