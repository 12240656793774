import styled from 'styled-components';
import { DEVICES } from '../../../config/appConfig';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
`;

export const DropdownButton = styled.div`
  padding: 16px 20px;
  border-radius: 9px;
  border: 1px solid #8b9e944d;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  background: rgba(211, 194, 180, 0.05);
  text-align: left;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 48px;
  cursor: pointer;
  .selectedItemStyle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
    width: 90%;
  }
  ${(props) => props.value && { color: ' #010915' }}
  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    font-size: 12px;
  }
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 140px;
  overflow-y: auto;
  padding: 3px 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  margin-top: 8px;
  scrollbar-color: #fff #e0e0e0;
  scrollbar-width: thin;
`;

export const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  ${(props) =>
    props.selectedValueStyle && { backgroundColor: ' #f58220', color: '#fff' }}

  &:hover {
    background-color: rgb(248, 218, 192);
  }

  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    font-size: 12px;
  }
`;
