import styled from 'styled-components';
import { DEVICES } from '../../../config/appConfig';
export const BottomImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
`;

export const ImageLeft = styled.img`
  position: absolute;
  width: 70%;
  height: 70px !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    object-fit: cover;
  }
`;

export const ImageRight = styled.img`
  position: absolute;
  width: 70%;
  height: 70px !important;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-radius: 10px;
  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    object-fit: cover;
  }
`;
