import styled from 'styled-components';

export const TabContainer = styled.div``;
export const TextStyle = styled.div`
  font-size: 12px;
  cursor: pointer;
  padding-bottom: 6px;
  color: ${(props) => (props.selectedIndex ? 'orange' : '#000')};
  border-bottom: ${(props) =>
    props.selectedIndex ? '1px solid orange' : 'none'};
  font-weight: ${(props) => (props.selectedIndex ? '600' : '400')};
  ${(props) => props.customStyle && { ...props.customStyle }}
`;
