import { stateList } from '../../utils/utils';
import { industriesList } from './constant';

export const formConstant = {
  FULL_NAME: 'fullName',
  BUSINESS_REGISTERED_NAME: 'businessName',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL_ADDRESS: 'email',
  CITY_STATE: 'cityState',
  INDUSTRY: 'industry',
  VOLUME: 'volume',
  TYPE_OF_BUSINESS: 'typeOfBusiness',
  LOCATION: 'location',
  ANY_SPACIAL_REQUIREMENT: 'anySpacialRequirement',
  UNITS: 'units',
};

export const formConfig = (t) => {
  return {
    inputs: [
      {
        name: formConstant.FULL_NAME,
        placeholder: t('app.FULL_NAME'),
        customStyle: 'customInputStyle1',
        validations: {
          required: true,
          minLength: 3,
        },
        type: 'text',
      },
      {
        name: formConstant.BUSINESS_REGISTERED_NAME,
        placeholder: t('app.BUSINESS_REGISTERED_NAME'),
        customStyle: 'customInputStyle2',
        validations: {
          required: true,
          minLength: 3,
        },
        type: 'text',
      },
      {
        name: formConstant.PHONE_NUMBER,
        placeholder: t('app.PHONE_NUMBER'),
        customStyle: 'customInputStyle3',
        validations: {
          required: true,
          pattern: /^[0-9]{10}$/g,
        },
        type: 'tel',
      },
      {
        name: formConstant.EMAIL_ADDRESS,
        placeholder: t('app.EMAIL_ID'),
        customStyle: 'customInputStyle4',
        validations: {
          required: true,
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/g,
        },
        type: 'text',
      },
      {
        name: formConstant.CITY_STATE,
        placeholder: t('app.STATE'),
        customStyle: 'customInputStyle5',
        validations: {
          required: true,
        },
        type: 'dropdown',
        options: stateList,
      },
      {
        name: formConstant.INDUSTRY,
        placeholder: t('app.INDUSTRY'),
        customStyle: 'customInputStyle6',
        validations: {
          required: true,
        },
        type: 'dropdown',
        options: industriesList,
      },
      {
        name: formConstant.VOLUME,
        placeholder: t('app.STORAGE_QUANTITY'),
        customStyle: 'customInputStyle7',
        validations: {
          required: true,
        },
        type: 'text-dropdown',
      },
      {
        name: formConstant.TYPE_OF_BUSINESS,
        type: 'radio',
        placeholder: t('app.TYPE_OF_BUSINESS'),
        customStyle: 'customInputStyle8',
        validations: {
          required: true,
        },
        options: [
          { name: 'isB2B', value: 'B2B', label: t('app.B2B_CHECKBOX') },
          { name: 'isB2C', value: 'B2C', label: t('app.B2C_CHECKBOX') },
        ],
      },
      {
        name: formConstant.LOCATION,
        placeholder: t('app.PREFERRED_WAREHOUSE_LOCATION'),
        customStyle: 'customInputStyle9',
        validations: {
          required: true,
        },
        type: 'text',
      },
      {
        name: formConstant.ANY_SPACIAL_REQUIREMENT,
        placeholder: t('app.ANY_SPACIAL_REQUIREMENT'),
        type: 'textarea',
        customStyle: 'customInputStyle10',
        validations: {
          required: false,
        },
      },
    ],
  };
};
// REGEX Constant
export const regex = {
  REMOVE_WHITE_SPACE: /\s{2,}/g,
  NUMBER: /[^0-9]/g,
};
