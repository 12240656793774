import styled from 'styled-components';
import { DEVICES } from '../../../config/appConfig';

export const TextAreaContainer = styled.div``;

export const CustomTextArea = styled.textarea`
  width: 100%;
  padding: 16px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
  box-sizing: border-box;
  outline: none;
  background: rgba(211, 194, 180, 0.05);
  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    font-size: 12px;
  }
`;
