import { useDispatch, useSelector } from 'react-redux';
import {
  Background,
  CloseModalButton,
  ModalContent,
  ModalContentWrapper,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
  NextButton,
  StyledFormContent,
  StyledFranchiseFormContent,
} from './BusinessEnquireForm.styled';
import { signupActions } from '../../store/slices/signupSlice';
import { useTranslation } from '../../hooks/useTranslation';
import { useEffect, useState } from 'react';
import { cardData, fransciseCardData } from './utils';
import { useMemo } from 'react';
import B2CForm from '../B2CForm/B2CForm';
import B2BForm from '../B2BForm/B2BForm';
import FormBottomSVG from '../EnquireForm/FormBottomSVG/FormBottomSVG';
import Card from './Card';
import FranchiseForm from '../FranchiseForm/FranchiseForm';

const BusinessEnquireForm = () => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [selected, setSelected] = useState(null);
  const [franchiseSelected, setFranchiseSelected] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showViewToggle, setViewToggle] = useState({});
  const { signupModal } = useSelector((state) => state.signup);

  useEffect(() => {
    if (signupModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    // Cleanup when the component unmounts or dialog closes
    return () => document.body.classList.remove('no-scroll');
  }, [signupModal]);

  const toglleViewAll = (viewId) => {
    setViewToggle((prev) => ({
      ...prev,
      [viewId]: !prev[viewId], //Toggle only for clicked view
    }));
  };
  // Handler function to close modal
  const handleCloseModal = (e) => {
    if (
      e.currentTarget === e.target ||
      e.currentTarget?.dataset?.testid === 'closeModalButton'
    ) {
      dispatch(signupActions.signupDataReset());
      dispatch(signupActions.hideSignupModal());
      setSelected(null);
      setFranchiseSelected(null);
      setCurrentStep(0);
    }
  };

  const renderCards = useMemo(() => {
    return cardData.map((card) => (
      <Card
        key={card.id}
        card={card}
        selected={selected}
        onClick={setSelected}
      />
    ));
  }, [cardData, selected]);

  const renderFranchiseCards = useMemo(() => {
    if (currentStep !== 1 || selected !== 'franchise') return null;

    return fransciseCardData.map((card) => (
      <Card
        key={card.id}
        card={card}
        selected={franchiseSelected}
        onClick={setFranchiseSelected}
        isFranchise
        benefits={card.benefits}
        eligibilitys={card.eligibilitys}
        showViewToggle={showViewToggle}
        toglleViewAll={toglleViewAll}
      />
    ));
  }, [
    currentStep,
    fransciseCardData,
    franchiseSelected,
    selected,
    showViewToggle,
  ]);

  const renderFirstStepForms = () => {
    if (currentStep === 0) {
      return <StyledFormContent>{renderCards}</StyledFormContent>;
    }

    if (currentStep === 1 && selected === 'franchise') {
      // Return the franchise form content with the list of franchise-specific cards
      return (
        <StyledFranchiseFormContent>
          {renderFranchiseCards}
        </StyledFranchiseFormContent>
      );
    }

    return null;
  };

  // Function to determine and return the modal title and subtitle based on the current step and selected option
  const getModalTitleContent = () => {
    if (currentStep === 1 && selected === 'franchise') {
      return (
        <>
          <div className='enquiry_modal_title'>
            {t('app.LETS_GROW_TOGETHER')}
          </div>
          <div className='enquiry_modal_sub-title'>
            {t('app.SELECT_THE_OPTION_THAT_SUITS_YOU_BEST')}
          </div>
        </>
      );
    }

    return (
      <>
        <div className='enquiry_modal_title'>
          {t('app.HAVE_A_BUSINESS_QUERY')} <span>{t('app.ENQUIRE_NOW')}</span>
        </div>
        <div className='enquiry_modal_sub-title'>
          {t('app.PLEASE_SELECT_AN_OPTION')}
        </div>
      </>
    );
  };

  const handleOnNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const isDisabled = useMemo(() => {
    if (currentStep === 0) return !selected; // Step 0: Disabled if not selected
    if (currentStep === 1) return !franchiseSelected; // Step 1: Disabled if franchise is not selected
    return false; // Otherwise, enabled
  }, [selected, currentStep, franchiseSelected]);

  const renderModalContent = () => {
    return (
      <>
        <ModalHeader>
          <ModalTitle>{getModalTitleContent()}</ModalTitle>
        </ModalHeader>
        <ModalContent>{renderFirstStepForms()}</ModalContent>
        <ModalFooter>
          <NextButton
            type='submit'
            onClick={handleOnNext}
            disabled={isDisabled}
          >
            {t('app.NEXT')}
          </NextButton>
        </ModalFooter>
        <FormBottomSVG />
      </>
    );
  };

  const renderEnquiryForm = () => {
    if (currentStep === 2) {
      return <FranchiseForm franchiseSelectedOption={franchiseSelected} />;
    }

    if (currentStep !== 1) {
      return renderModalContent();
    }

    switch (selected) {
      case 'business':
        return <B2CForm />;
      case 'warehousing':
        return <B2BForm />;
      default:
        return renderModalContent();
    }
  };

  const getClassNameForForms = () => {
    if (currentStep === 2 || (currentStep === 1 && selected !== 'franchise')) {
      return 'enquiry_form';
    }
    return '';
  };
  return (
    <>
      {signupModal && (
        <Background onClick={handleCloseModal}>
          <ModalWrapper className={getClassNameForForms()}>
            <ModalContentWrapper>{renderEnquiryForm()}</ModalContentWrapper>
            <CloseModalButton
              aria-label='Close modal'
              onClick={handleCloseModal}
              data-testid='closeModalButton'
            />
          </ModalWrapper>
        </Background>
      )}
    </>
  );
};

export default BusinessEnquireForm;
