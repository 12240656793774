// chatbotSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isChatOpen: false,
};

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    toggleBot: (state, action) => {
      state.isChatOpen = action.payload;
    },
  },
});

export const { toggleBot } = chatbotSlice.actions;
export const chatbotReducer = chatbotSlice.reducer;
