import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ButtonWrapper,
  CheckboxContainer,
  CheckboxWithNestedCheckboxWrapper,
  ContainerWrapper,
  ErrorMsgStyled,
  FormContainer,
  NestedCheckboxContainer,
  NestedInputContainer,
  TabSectionStyle,
} from './B2CForm.styled';
import FormHeaderTitle from '../EnquireForm/FormHeaderTitle/FormHeaderTitle';
import CustomInput from '../EnquireForm/InputComp/InputText';
import CheckboxWithText from '../EnquireForm/CheckboxWithText/CheckboxWithText';
import { checkboxMap, formConfig, formConstant, regex } from './config';
import { useTranslation } from '../../hooks/useTranslation';
import FormBottomSVG from '../EnquireForm/FormBottomSVG/FormBottomSVG';
import Button from '../EnquireForm/Button/Button';
import Dropdown from '../EnquireForm/Dropdown/Dropdown';
import Tab from '../EnquireForm/Tab/Tab';

const B2CForm = () => {
  const t = useTranslation();
  const { inputs, checkboxes } = formConfig(t);
  const initialFormState = {
    fullName: '',
    businessName: '',
    phoneNumber: '',
    email: '',
    cityState: '',
    isSmallSizeShipments: false,
    isPartTruckLoad: false,
    isFullTruckLoad: false,
    isCrossBorder: false,
    isSmallSizeOption: '',
    partTruckLoadValue: '',
    fullTruckLoadValue: '',
    crossBorderValue: '',
    smallSizeShipmentWeight: '',
  };
  const initialErrorState = {
    fullNameError: '',
    businessNameError: '',
    phoneNumberError: '',
    emailError: '',
    cityStateError: '',
    isSmallSizeShipmentsError: '',
    isPartTruckLoadError: '',
    isFullTruckLoadError: '',
    isCrossBorderError: '',
    isSmallSizeOptionError: '',
    partTruckLoadValueError: '',
    fullTruckLoadValueError: '',
    crossBorderValueError: '',
    checkboxSelectionError: '',
  };
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const [selectedValue, setSelectedValue] = useState(
    checkboxes[0]?.subSmallSizeShipmentWeightTabs[0] || ''
  );

  // Function to handle tab selection, now accepts both index and value
  const handleTabSelect = (value) => {
    setSelectedValue(value);
  };

  // Handle field change
  const handleChange = (e, selectedSubOptionValue) => {
    const { name, value, checked, type } = e.target;
    let normalizedValue = value
      ?.trimStart()
      ?.replace(regex.REMOVE_WHITE_SPACE, ' ');
    // If the field is one of the numeric-only fields, ensure only numbers are allowed
    if (
      name === formConstant.PART_TRUCK_LOAD_VALUE ||
      name === formConstant.FULL_TRUCK_LOAD_VALUE ||
      name === formConstant.CROSS_BORDER_VALUE
    ) {
      // Only allow numbers (use regex to strip out non-numeric characters)
      normalizedValue = normalizedValue.replace(regex.NUMBER, '');
    }

    // Update formData based on the name and checkbox value
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      // Handle changes for small size shipments
      if (name === formConstant.SMALL_SIZE_SHIPMENTS) {
        updatedFormData[name] = checked;
        updatedFormData.isSmallSizeOption = '';
      } else if (name.startsWith(formConstant.IS_SMALL_SIZE_OPTION)) {
        updatedFormData.isSmallSizeOption = checked
          ? selectedSubOptionValue
          : '';
      } else if (name == formConstant.EMAIL_ADDRESS) {
        let emailValue = value?.trim();
        setFormData((prevState) => ({
          ...prevState,
          [name]: emailValue,
        }));
      } else {
        updatedFormData[name] = type === 'checkbox' ? checked : normalizedValue;
      }

      // Check if any checkbox is selected
      if (type === 'checkbox') {
        const isAnyCheckboxSelected = Object.keys(checkboxMap).some(
          (key) => updatedFormData[checkboxMap[key]] === true
        );
        // Set errors based on checkbox validation
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // If no checkboxes are selected, show error
          if (!isAnyCheckboxSelected) {
            updatedErrors.checkboxSelectionError = t(
              'app.YOU_MUST_SELECT_AT_LEAST_ONE_REQUIREMENT'
            );
            setFormData({
              isSmallSizeOption: '',
              partTruckLoadValue: '',
              fullTruckLoadValue: '',
              crossBorderValue: '',
            });
          } else {
            delete updatedErrors.checkboxSelectionError;
          }
          return updatedErrors;
        });
      }

      return updatedFormData;
    });
    // Validate individual field
    validate(name, normalizedValue);
  };
  // Validation function
  const validate = (name, value) => {
    const newErrors = { ...errors };
    const field = inputs.find((input) => input.name === name);
    if (field) {
      const validations = field.validations;

      // Check required validation
      if (validations.required && !value) {
        newErrors[`${name}Error`] = `${field.placeholder} ${t(
          'app.IS_REQUIRED'
        )}`;
      }
      // Check pattern validation (e.g., email, phone number)
      else if (validations.pattern && !validations.pattern.test(value)) {
        newErrors[`${name}Error`] = `${t('app.INVALID')} ${field.placeholder}`;
      }
      // Check minLength validation
      else if (validations.minLength && value.length < validations.minLength) {
        newErrors[`${name}Error`] = `${field.placeholder} ${t(
          'app.MUST_BE_AT_LEAST_CHARACTERS_LONG',
          { value: validations.minLength }
        )}`;
      }
      // Clear error if all validations pass
      else {
        newErrors[`${name}Error`] = '';
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = {};

    // Validate all fields
    inputs.forEach((input) => {
      const value = formData[input.name];
      const validationResult = validate(input.name, value);
      if (validationResult[`${input.name}Error`]) {
        newErrors[`${input.name}Error`] =
          validationResult[`${input.name}Error`];
        formIsValid = false;
      }
    });

    // Validate isSmallSizeOption if isSmallSizeShipments is true
    if (formData.isSmallSizeShipments && !formData.isSmallSizeOption) {
      newErrors['isSmallSizeOptionError'] = t('app.FIELD_REQUIRED');
      formIsValid = false;
    }

    // Validate checkbox values
    checkboxes.forEach((checkbox) => {
      if (
        checkbox.name !== formConstant.SMALL_SIZE_SHIPMENTS &&
        formData[checkbox.name]
      ) {
        checkbox.subOptions.forEach((nested) => {
          if (formData[checkbox.name] && !formData[nested.name]) {
            newErrors[`${nested.name}Error`] = `${nested.placeholder} ${t(
              'app.IS_REQUIRED'
            )}`;
            formIsValid = false;
          }
        });
      }
    });

    // Check if any checkbox requirement is selected
    const isAnyCheckboxSelected = Object.keys(formData).some(
      (key) => key.startsWith('is') && formData[key]
    );
    if (!isAnyCheckboxSelected) {
      newErrors.checkboxSelectionError = t(
        'app.YOU_MUST_SELECT_AT_LEAST_ONE_REQUIREMENT'
      );
      formIsValid = false;
    }

    setErrors(newErrors);

    if (!formIsValid) {
      toast.error(t('app.PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_CORRECTLY'));
      return;
    }

    toast.success(t('app.FORM_SUBMITTED_SUCCESSFULLY'));
    setFormData(initialFormState);
  };

  return (
    <ContainerWrapper>
      <div className='headerContainer'>
        <FormHeaderTitle
          headerLeftTitle={t('app.HAVE_A_BUSINESS_QUERY')}
          headerRightTitle={t('app.ENQUIRE_NOW')}
          subHeaderTitle={t('app.B2B_SUB_TITLE')}
        />
      </div>
      <FormContainer>
        <div className='inputFlexStyled'>
          {inputs?.map((value, index) => {
            if (value.type === 'dropdown') {
              return (
                <div key={value.name} className={value.customStyle}>
                  <Dropdown
                    name={value.name}
                    value={formData[value.name]}
                    options={value.options}
                    onChange={handleChange}
                    placeholder={value.placeholder}
                  />
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            } else {
              return (
                <div className={value.customStyle}>
                  <CustomInput
                    key={value.name}
                    type={value.type}
                    placeholder={value.placeholder}
                    value={formData[value.name]}
                    name={value.name}
                    id={value.name}
                    onChange={handleChange}
                  />

                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            }
          })}
        </div>
      </FormContainer>
      <div className='checkboxWithLabelFlexStyled'>
        <div className='checkboxHeaderIsShowOnSMDevice'>
          <h3>{t('app.CHOOSE_YOUR_REQUIREMENT')}</h3>
        </div>
        {checkboxes?.map((checkbox, index) => (
          <CheckboxWithNestedCheckboxWrapper>
            <CheckboxContainer
              key={index}
              checked={formData[checkbox.name]}
              className={checkbox.customStyle}
            >
              <CheckboxWithText
                checked={formData[checkbox.name]}
                name={checkbox.name}
                onChange={handleChange}
                label={checkbox.label}
                reverseLayout={true}
              />
            </CheckboxContainer>
            {/* Show nested checkboxes only for "Small Size Shipments" */}
            {formData[checkbox.name] &&
              checkbox.name === 'isSmallSizeShipments' && (
                <>
                  <h3 className='subOptionsHeaderStyled' id='subOptionalStyled'>
                    {checkbox.subOptionsLabel}
                  </h3>
                  <NestedCheckboxContainer>
                    <TabSectionStyle>
                      {checkbox?.subSmallSizeShipmentWeightTabs?.map(
                        (item, index) => (
                          <Tab
                            key={index}
                            item={item}
                            selectedIndex={item == selectedValue}
                            onTabSelect={() => handleTabSelect(item)}
                          />
                        )
                      )}
                    </TabSectionStyle>
                    {checkbox.subOptions?.map((nested, nestedIndex) => (
                      <CheckboxWithText
                        key={nestedIndex}
                        checked={formData.isSmallSizeOption === nested.value}
                        name={nested.name}
                        onChange={(e) => handleChange(e, nested.value)}
                        label={nested.label}
                        value={nested.value}
                      />
                    ))}

                    {formData.isSmallSizeShipments &&
                      !formData.isSmallSizeOption && (
                        <ErrorMsgStyled>
                          {t('app.FIELD_REQUIRED')}
                        </ErrorMsgStyled>
                      )}
                  </NestedCheckboxContainer>
                </>
              )}
            {formData[checkbox.name] &&
              checkbox.name !== 'isSmallSizeShipments' && (
                <NestedInputContainer>
                  <h3 className='subOptionsHeaderStyled'>
                    {checkbox.subOptionsLabel}
                  </h3>

                  {checkbox.subOptions?.map((nested, nestedIndex) => (
                    <>
                      <CustomInput
                        placeholder={nested.placeholder}
                        name={nested.name}
                        value={formData[nested.name] || ''}
                        onChange={handleChange}
                        id={nestedIndex}
                        type={nested.type}
                        customStyle={{
                          padding: '10px 14px',
                          fontSize: '11px',
                          borderRadius: '5px',
                        }}
                      />
                      {formData[checkbox.name] && !formData[nested.name] && (
                        <ErrorMsgStyled>
                          {errors[`${nested.name}Error`] ||
                            t('app.FIELD_REQUIRED')}
                        </ErrorMsgStyled>
                      )}
                    </>
                  ))}
                </NestedInputContainer>
              )}
          </CheckboxWithNestedCheckboxWrapper>
        ))}
      </div>
      {errors.checkboxSelectionError && (
        <ErrorMsgStyled> {errors.checkboxSelectionError}</ErrorMsgStyled>
      )}
      <ButtonWrapper>
        <Button handleSubmit={handleSubmit} title={t('app.SUBMIT_BUTTON')} />
      </ButtonWrapper>
      <FormBottomSVG />
    </ContainerWrapper>
  );
};

export default B2CForm;
