export const cardData = [
  {
    id: 'business',
    title: 'Business Queries',
    description:
      'D2C Shipments | Part Truck Load | Full Truck Load | Cross-Border Shipments',
    image: '/images/enquiryForm/BusinessQueries.svg',
  },
  {
    id: 'warehousing',
    title: 'Warehousing',
    description: '3PL | Dark Warehouses | Fulfillment & Distribution',
    image: '/images/enquiryForm/Warehousing_3PL.svg',
  },
  {
    id: 'franchise',
    title: 'Franchise Partner',
    description:
      'Courier booking Counter | Multipurpose parcel booking facility',
    image: '/images/enquiryForm/Franchise.svg',
  },
];

export const fransciseCardData = [
  {
    id: 'courier_booking',
    title: 'Run a XpressBees Courier Booking Counter',
    description:
      'Start Your Own XpressBees Courier Booking Counter And Grow Your Business Effortlessly!',
    image: '/images/enquiryForm/courier_booking.svg',
    benefits: [
      'Affordable Setup Cost',
      'Earn Profits With Every Parcel Booked.',
      'Extra Income From Packaging And Insurance Services',
    ],
    eligibilitys: [
      '60-80 Sqft Space On A Main Road',
      'Good Communication Skills',
    ],
  },
  {
    id: 'parcel_booking',
    title: 'Run Multipurpose Parcel Booking Facility for XpressBees',
    description:
      'Effortlessly Manage Multipurpose Parcel Bookings With XpressBees For Seamless Deliveries.',
    image: '/images/enquiryForm/parcel_booking.svg',
    benefits: [
      'Low Setup Cost',
      'Minimum Business Guaranty',
      'The Delivery Should Be Made To The Pincode Assigned To The Respective Franchise',
    ],
    eligibilitys: [
      'Minimum 100 Sqft. Of Floor Space To Keep Shipments',
      'The Franchise Owner Has To Run The Franchise Business As A Full Time/ Part Time.',
      'Manage Onboarding Of Delivery Riders Through Own Source',
    ],
  },
];
