import styled from 'styled-components';

export const ButtonStyled = styled.button`
  padding: 24px 28px;
  background-color: #f58220;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.5px;
  min-width: 200px;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgb(242, 152, 72);
  }
`;
