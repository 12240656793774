import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ButtonWrapper,
  ContainerWrapper,
  ErrorMsgStyled,
  FormContainer,
} from './B2BForm.styled';
import FormHeaderTitle from '../EnquireForm/FormHeaderTitle/FormHeaderTitle';
import CustomInput from '../EnquireForm/InputComp/InputText';
import CheckboxWithText from '../EnquireForm/CheckboxWithText/CheckboxWithText';
import { formConfig, formConstant, regex } from './config';
import { useTranslation } from '../../hooks/useTranslation';
import FormBottomSVG from '../EnquireForm/FormBottomSVG/FormBottomSVG';
import Button from '../EnquireForm/Button/Button';
import Dropdown from '../EnquireForm/Dropdown/Dropdown';
import TextArea from '../EnquireForm/TextArea/TextArea';
import { REGEX_PATTERNS } from '../../config/constants';

const B2BForm = () => {
  const t = useTranslation();
  const { inputs } = formConfig(t);
  const initialFormState = {
    fullName: '',
    businessName: '',
    phoneNumber: '',
    email: '',
    cityState: '',
    typeOfBusiness: '',
    location: '',
    anySpacialRequirement: '',
    industry: '',
    volume: '',
  };
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({
    fullNameError: '',
    businessNameError: '',
    phoneNumberError: '',
    emailError: '',
    cityStateError: '',
    typeOfBusinessError: '',
    locationError: '',
    anySpacialRequirementError: '',
    industryError: '',
    volumeError: '',
  });

  // Handle change for all input fields
  const handleChange = (e, typeOfBusinessValue) => {
    const { name, value, checked } = e.target;
    let normalizedValue = value
      ?.trimStart()
      ?.replace(regex.REMOVE_WHITE_SPACE, ' ');

    if (name === formConstant.VOLUME) {
      // Only allow numbers (use regex to strip out non-numeric characters)
      normalizedValue = normalizedValue.replace(regex.NUMBER, '');
    }
    if (name.startsWith(formConstant.TYPE_OF_BUSINESS)) {
      // Handle type of business options (only allow one selected)
      setFormData((prevState) => ({
        ...prevState,
        typeOfBusiness: checked ? typeOfBusinessValue : '',
      }));
    } else if (name == formConstant.EMAIL_ADDRESS) {
      let emailValue = value?.trim();
      setFormData((prevState) => ({
        ...prevState,
        [name]: emailValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: normalizedValue,
      }));
    }
    // Validate the field on change
    validate(name, normalizedValue);
  };
  // Validation implement
  const validate = (name, value) => {
    const newErrors = { ...errors };
    const field = inputs.find((input) => input.name === name);

    if (field) {
      const validations = field.validations;

      // Check required validation
      if (validations.required && !value) {
        newErrors[`${name}Error`] = `${field.placeholder} ${t(
          'app.IS_REQUIRED'
        )}`;
      }
      // Check pattern validation (for email, phone number, etc.)
      else if (validations.pattern && !validations.pattern.test(value)) {
        newErrors[`${name}Error`] = `${t('app.INVALID')} ${field.placeholder}`;
      }
      // Check minLength validation
      else if (validations.minLength && value.length < validations.minLength) {
        newErrors[`${name}Error`] = `${field.placeholder} ${t(
          'app.MUST_BE_AT_LEAST_CHARACTERS_LONG',
          { value: validations.minLength }
        )}`;
      }
      // Validate dropdown (industry)
      else if (
        name === formConstant.INDUSTRY &&
        validations.required &&
        !value
      ) {
        newErrors[`${name}Error`] = `${
          ('app.PLEASE_SELECT_A', { value: field.placeholder })
        }`;
      }
      // Validate radio button (typeOfBusiness)
      else if (
        name === formConstant.TYPE_OF_BUSINESS &&
        validations.required &&
        !value
      ) {
        newErrors[`${name}Error`] = `${
          ('app.PLEASE_SELECT_A', { value: field.placeholder })
        }`;
      } else {
        newErrors[`${name}Error`] = '';
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = {};

    // Validate all fields
    inputs.forEach((input) => {
      const value = formData[input.name];
      const validationResult = validate(input.name, value);
      if (validationResult[`${input.name}Error`]) {
        newErrors[`${input.name}Error`] =
          validationResult[`${input.name}Error`];
        formIsValid = false;
      }
    });
    setErrors(newErrors);

    if (!formIsValid) {
      toast.error(t('app.PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_CORRECTLY'));
      return;
    }
    toast.success(t('app.FORM_SUBMITTED_SUCCESSFULLY'));
    setFormData(initialFormState);
  };

  return (
    <ContainerWrapper>
      <div className='headerContainer'>
        <FormHeaderTitle
          headerLeftTitle={t('app.HAVE_A_BUSINESS_QUERY')}
          headerRightTitle={t('app.ENQUIRE_NOW')}
          subHeaderTitle='3PL | Warehousing | Dark Warehouses | Fulfillment & Distribution'
        />
      </div>
      <FormContainer>
        <div className='inputFlexStyled'>
          {inputs?.map((value, index) => {
            if (value.type === 'dropdown') {
              return (
                <div key={value.name} className={value.customStyle}>
                  <Dropdown
                    name={value.name}
                    value={formData[value.name]}
                    options={value.options}
                    onChange={handleChange}
                    placeholder={value.placeholder}
                  />
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            } else if (value.type === 'text-dropdown') {
              return (
                <div className='inputWithDropdownContainer'>
                  <div className='inputWithDropdown'>
                    <div className={value.customStyle}>
                      <CustomInput
                        type={value.type}
                        placeholder={value.placeholder}
                        value={formData[value.name]}
                        name={value.name}
                        id={value.name}
                        onChange={handleChange}
                        customStyle={{ border: 'none' }}
                      />
                    </div>
                    <div className='pipeStyle' />
                    <div className='unitContainer'>
                      <h5 className='unitStyle'>{t('app.CUBIC_METER')}</h5>
                    </div>
                  </div>
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            } else if (value.type === 'radio') {
              return (
                <div key={value.name} className={value.customStyle}>
                  <div className='radioLabel'>{value.placeholder}</div>
                  <div className='radioStyled'>
                    {value.options.map((option) => {
                      return (
                        <CheckboxWithText
                          key={option.name}
                          name={value.name}
                          label={option.label}
                          value={option.value}
                          checked={formData[value.name] === option.value}
                          onChange={handleChange}
                          labelCustomStyle={{
                            fontSize: '12px',
                            fontWeight: '400',
                          }}
                        />
                      );
                    })}
                  </div>
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            } else if (value.type == 'textarea') {
              return (
                <div key={value.name} className={value.customStyle}>
                  <TextArea
                    name={value.name}
                    value={formData[value.name]}
                    placeholder={value.placeholder}
                    onChange={handleChange}
                    rows={3}
                    cols={30}
                  />
                </div>
              );
            } else {
              return (
                <div key={value.name} className={value.customStyle}>
                  <CustomInput
                    type={value.type}
                    placeholder={value.placeholder}
                    value={formData[value.name]}
                    name={value.name}
                    id={value.name}
                    onChange={handleChange}
                  />
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {' '}
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            }
          })}
        </div>
      </FormContainer>
      <ButtonWrapper>
        <Button
          type='button'
          title={t('app.SUBMIT_BUTTON')}
          handleSubmit={handleSubmit}
        />
      </ButtonWrapper>
      <FormBottomSVG />
    </ContainerWrapper>
  );
};

export default B2BForm;
