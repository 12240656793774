import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { DEVICES } from '../../config/appConfig';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
`;

export const ModalWrapper = styled.div`
  position: relative;
  padding: 32px 20px 24px 20px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10001;
  border-radius: 10px;
  width: 80%;
  max-width: 1320px;
  gap: 20px;
  margin: 1.75rem auto;

  &.enquiry_form {
    padding: 0px;
    max-width: 550px;
  }

  @media ${DEVICES.sMobile} {
    margin: 10px;
    width: 92%;
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const CloseModalButton = styled(MdClose)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 1px;
  background: rgba(191, 132, 49, 0.1);
  color: rgba(191, 132, 49, 1);
  border-radius: 50%;
  z-index: 10;
  border: 1.2px solid rgba(191, 132, 49, 1);
  @media ${DEVICES.sMobile} {
    width: 22px;
    height: 22px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  .enquiry_modal_title {
    font-size: 24px;
    font-weight: 600;
    span {
      font-weight: 500;
      color: #f58220;
    }
  }
  .enquiry_modal_sub-title {
    font-size: 14px;
    font-weight: 400;
    color: #010915;
  }
  @media ${DEVICES.sMobile} {
    gap: 8px;
    .enquiry_modal_title {
      font-size: 20px;
    }
    .enquiry_modal_sub-title {
      font-size: 14px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NextButton = styled.button`
  display: flex;
  width: 200px;
  height: 50px;
  padding: 24px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #f58220;
  color: #fff;
  border: none;
  flex-shrink: 0;
  z-index: 10;
  margin-bottom: 40px;

  ${({ disabled }) => {
    if (disabled) {
      return {
        opacity: 0.4,
        color: '#ADADAD',
        background: '#f5f5f5',
        border: `1px solid #D6D6D6`,
      };
    }
  }}

  &:hover {
    ${({ disabled }) => {
      if (!disabled) {
        return {
          background: '#f58220',
          borderColor: '#fd8721',
        };
      } else {
        return { cursor: 'not-allowed' };
      }
    }}
  }
  @media ${DEVICES.sMobile} {
    width: 95%;
    text-align: center;
  }
`;

export const StyledFormContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  .form-card {
    max-width: 300px;
    flex-shrink: 0;
    border-radius: 7px;
    border: 1px solid rgba(139, 158, 148, 0.3);
    padding: 30px 20px;
    background: #fdfcfb;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    width: auto;
    input {
      display: none;
    }
  }
  .selected-card {
    border: 1.2px solid #161c2d;
  }

  .card-title {
    align-self: stretch;
    color: #000;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .card-description {
    color: rgba(1, 9, 21, 0.75);

    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 131%; /* 14.41px */
    margin: 0px;
  }

  @media ${DEVICES.sMobile} {
    width: 100%;
    .form-card {
      width: 100%;
    }
  }
`;

export const StyledFranchiseFormContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  .form-card {
    width: calc(-30px + 50%);
    flex-shrink: 0;
    border-radius: 7px;
    border: 1px solid rgba(139, 158, 148, 0.3);
    padding: 30px 20px;
    background: #fdfcfb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    position: relative;
    overflow: auto;
  }
  .card-header {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
  }
  .checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    width: auto;
    input {
      display: none;
    }
  }
  .selected-card {
    border: 1.2px solid #161c2d;
  }

  .card-title {
    color: #000;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .card-description {
    color: rgba(1, 9, 21, 0.75);

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 13.2px */
    text-transform: capitalize;
  }

  .card-content {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .benefits,
    .eligibility {
      width: 50%;
      h3 {
        align-self: stretch;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
        text-transform: capitalize;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
          display: flex;
          align-items: center;
          gap: 6px;
          span {
            color: #000;

            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 12px */
            text-transform: capitalize;
          }
        }
        .show-more-btn {
          width: fit-content;
          background: none;
          border: none;
          display: inline-flex;
          align-items: center;
          gap: 3px;
          font-size: 10px;
          font-weight: 600;
          color: #161c2d;
          &.selected {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
  }

  @media ${DEVICES.sMobile} {
    .form-card {
      width: 100%;
      .card-header {
        flex-direction: column;
      }
      .card-content {
        flex-direction: column;

        .benefits,
        .eligibility {
          width: 100%;
        }
      }
    }
  }
`;

export const Checkmark = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: ${(props) =>
    props.checked ? '1px solid #161c2d' : '1px solid #C7C7C7'};
  border-radius: 4px;
  position: relative;
  transition: background-color 0.2s ease;
  align-self: center;
  min-width: 16px;
  min-height: 16px;
  &:after {
    content: '${(props) => (props.checked ? '✔' : '')}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #161c2d;
    font-size: 16px;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    transition: opacity 0.2s ease;
  }
`;
