import styled from 'styled-components';
import { DEVICES } from '../../../config/appConfig';

export const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  width: auto;
  max-width: 100%;
  ${({ reverseLayout }) =>
    reverseLayout &&
    `
   @media ${DEVICES.sMobile}, ${DEVICES.mobile} ,${DEVICES.tablet}{
   display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: start;
    padding:2px 6px;
}
  `}
`;

export const Checkmark = styled.div`
  display: inline-flex;
  width: 16px;
  height: 16px;
  border: ${(props) =>
    props.checked ? '1px solid #161c2d' : '1px solid #C7C7C7'};
  border-radius: 4px;
  position: relative;
  transition: background-color 0.2s ease;
  align-self: center;
  min-width: 16px;
  min-height: 16px;
  &:after {
    content: '${(props) => (props.checked ? '✔' : '')}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #161c2d;
    font-size: 12px;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    transition: opacity 0.2s ease;
  }
`;

export const HiddenCheckbox = styled.input`
  display: none;
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: start;
  ${(props) => props.labelCustomStyle && { ...props.labelCustomStyle }}
`;
