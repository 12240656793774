import React from 'react';
import { CustomInputStyled } from './InputText.styled';

const InputText = ({
  value,
  onChange,
  placeholder = '',
  type = 'text',
  icon = null,
  name = '',
  id = '',
  customStyle = {},
  customInputStyle,
  ...props
}) => {
  return (
    <CustomInputStyled
      type={type}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      customStyle={customStyle}
      {...props}
    />
  );
};

export default InputText;
