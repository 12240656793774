import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';

export const ContainerWrapper = styled.div`
  padding: 16px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .headerContainer {
    text-align: start;
  }

  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    max-width: 100%;
    margin-top: 16px;
  }
`;
export const FormContainer = styled.div`
  .inputFlexStyled {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    padding-top: 16px;
    width: 100%;
  }
  .customInputStyle1 {
    width: calc(100%);
    text-align: start;
  }
  .customInputStyle2 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle3 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle4 {
    width: calc(100%);
    text-align: start;
  }
  .customInputStyle5 {
    width: calc(33% - 9px);
    text-align: start;
  }
  .customInputStyle6 {
    width: calc(33% - 9px);
    text-align: start;
  }
  .customInputStyle7 {
    width: calc(33% - 9px);
    text-align: start;
  }

  @media ${DEVICES.mobile}, ${DEVICES.tablet} {
    .customInputStyle1 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle2 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle5 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle6 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle7 {
      width: calc(100%);
      text-align: start;
    }
  }
  @media ${DEVICES.sMobile} {
    .customInputStyle1 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle2 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle3 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle5 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle6 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle7 {
      width: calc(100%);
      text-align: start;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px;
  @media ${DEVICES.sMobile} {
    margin-top: 0px;
    width: 100%;
  }
`;

export const ErrorMsgStyled = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: red;
  text-align: start;
`;
