import React, { useState, useEffect, useRef } from 'react';
import {
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownItem,
} from './Dropdown.styled';
import { icons } from '../../../icons/icons';

const Dropdown = ({
  name,
  value,
  options,
  onChange,
  placeholder,
  customStyle,
  ...props
}) => {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown container

  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  const handleOptionSelect = (selectedValue) => {
    setSelectedDropdownValue(selectedValue);
    onChange({ target: { name, value: selectedValue } });
    setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Attach the event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);
    // Clean up the event listener when component is unmounted or isOpen changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <DropdownContainer ref={dropdownRef} className={customStyle} {...props}>
      <DropdownButton value={value} onClick={toggleDropdown}>
        <span className='selectedItemStyle'>{value || placeholder}</span>
        {icons.downArrow}
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleOptionSelect(option.value)}
              selectedValueStyle={option.value === selectedDropdownValue}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
