import styled from 'styled-components';
import { DEVICES } from '../../../config/appConfig';

export const CustomInputStyled = styled.input`
  padding: 16px 20px;
  border-radius: 9px;
  border: 1px solid #8b9e944d;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  background: rgba(211, 194, 180, 0.05);
  max-height: 48px;
  ${(props) => props.customStyle && { ...props.customStyle }}
  @media ${DEVICES.sMobile}, ${DEVICES.mobile} {
    font-size: 12px;
  }
`;
