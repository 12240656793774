import React from 'react';
import { HeaderTitleContainer } from './FormHeaderTitle.styled';

const FormHeaderTitle = ({
  headerLeftTitle = '',
  headerRightTitle = '',
  subHeaderTitle = '',
  subHeaderTitle2 = '',
}) => {
  return (
    <HeaderTitleContainer>
      <h2>
        {headerLeftTitle} <span>{headerRightTitle}</span>
      </h2>
      {subHeaderTitle && (
        <h3 className='subHeaderStyle'>
          {subHeaderTitle.split(' | ').map((item, index) => (
            <React.Fragment key={index}>
              <span className='textColor'>{item}</span>
              {index < subHeaderTitle.split(' | ').length - 1 && (
                <span className='pipeColor'></span>
              )}
            </React.Fragment>
          ))}
        </h3>
      )}
      {subHeaderTitle2 && (
        <h3 className='subHeaderStyle textColor'>{subHeaderTitle2} </h3>
      )}
    </HeaderTitleContainer>
  );
};

export default FormHeaderTitle;
