import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';

export const ContainerWrapper = styled.div`
  padding: 16px;
  text-align: center;
  position: relative;
  .checkboxHeaderIsShowOnSMDevice {
    display: none;
  }
  @media ${DEVICES.sMobile} {
    max-width: 100%;
  }
  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    padding-top: 24px;
    .inputFlexStyled {
      display: flex;
      width: 100%;
      gap: 24px;
      padding-top: 24px;
      justify-content: space-between;
    }
  }
  @media ${DEVICES.mobile}, ${DEVICES.tablet}, ${DEVICES.tablet} {
    min-height: auto;
  }
`;

export const FormContainer = styled.div`
  .inputFlexStyled {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 16px;
    width: 100%;
  }
  .customInputStyle1 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle2 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle3 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle4 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle5 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle6 {
    width: calc(50% - 8px);
    text-align: start;
  }
  .customInputStyle7 {
    width: calc(75%);
    text-align: start;
  }
  .customInputStyle8 {
    width: calc(30% - 11px);
    text-align: start;
  }
  .customInputStyle9 {
    width: calc(100%);
    text-align: start;
  }
  .customInputStyle10 {
    width: calc(100%);
    text-align: start;
  }
  .radioLabel {
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .radioStyled {
    display: flex;
    gap: 16px;
    margin-top: 6px;
  }
  .inputWithDropdownContainer {
    width: calc(50% - 8px);
  }
  .inputWithDropdown {
    display: flex;
    border: 1px solid #8b9e944d;
    border-radius: 9px;
    max-height: 48px;
    justify-content: flex-end;
  }
  .unitContainer {
    width: calc(25%);
    background-color: #f09c54;
    border-radius: 0px 8px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pipeStyle {
    border-left: 1px solid #8b9e944d;
    margin-left: 10px;
  }
  .unitStyle {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
    text-align: center;
  }

  @media ${DEVICES.mobile}, ${DEVICES.tablet} {
    .customInputStyle1 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle2 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle5 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle6 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle7 {
      width: calc(70%);
      text-align: start;
    }
    .customInputStyle8 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .radioLabel {
      font-size: 14px;
    }
    .customInputStyle9 {
      width: calc(100%);
      text-align: start;
    }
    .inputWithDropdownContainer {
      width: calc(50% - 8px);
    }
    /* .unitContainer {
      width: calc(25%);
      background-color: orange;
      border-radius: 0px 8px 8px 0px;
    } */
  }
  @media ${DEVICES.sMobile} {
    .customInputStyle1 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle2 {
      width: 100%;
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle5 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle6 {
      width: calc(100%);
      text-align: start;
    }
    .customInputStyle7 {
      width: calc(70%);
      text-align: start;
    }
    .customInputStyle8 {
      width: calc(100%);
      text-align: start;
    }
    .radioLabel {
      font-size: 14px;
    }
    .customInputStyle9 {
      width: calc(100%);
      text-align: start;
    }
    .inputWithDropdownContainer {
      width: calc(100%);
    }

    /* .unitContainer {
      width: 30%;
      background-color: orange;
      border-radius: 0px 8px 8px 0px;
    } */
  }
  @media ${DEVICES.tablet} {
    .customInputStyle1 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle2 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle3 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle4 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle5 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle6 {
      width: calc(50% - 8px);
      text-align: start;
    }
    .customInputStyle7 {
      width: calc(70%);
      text-align: start;
    }
    .customInputStyle8 {
      width: calc(50% - 8px);
      text-align: start;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px;
  @media ${DEVICES.sMobile} {
    margin-top: 0px;
    width: 100%;
  }
  @media ${DEVICES.mobile}, ${DEVICES.tablet} {
    margin-top: 0px;
  }
`;

export const ErrorMsgStyled = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: red;
  text-align: start;
`;
