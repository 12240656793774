import React from 'react';
import {
  CheckboxWrapper,
  Checkmark,
  HiddenCheckbox,
  Label,
} from './CheckboxWithText.styled';

const CheckboxWithText = ({
  checked,
  onChange,
  label,
  reverseLayout,
  value,
  labelCustomStyle,
  ...props
}) => {
  return (
    <CheckboxWrapper reverseLayout={reverseLayout} checked={checked}>
      <HiddenCheckbox
        type='checkbox'
        checked={checked}
        onChange={(e) => onChange(e, value)}
        {...props}
      />
      <Checkmark checked={checked} />
      {label && <Label labelCustomStyle={labelCustomStyle}>{label}</Label>}
    </CheckboxWrapper>
  );
};

export default CheckboxWithText;
