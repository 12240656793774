import React, { useEffect, useState, useRef } from 'react';
import {
  fetchHomeButtonsOptions,
  fetchOptionsOfInput,
  sendOTPFunction,
  validateOTPFunction,
  fetchIntentOfInput,
  fetchIntentsFromStorage,
  getFutureDeliveryDates,
  createStatusTicket,
  createComplintTicket,
  createUpdateTicket,
  validateShippingID,
  getShipmentTypeFromInput,
  getTicketDetails,
} from './utils/OptionsHandler';
import {
  getIntentName,
  getButtonName,
  getIntentTicket,
  getCurrentFlow,
  getNumber,
  clearSessionStorage,
  getAWBValue,
  setAWBValue,
  setIntentValue,
  getIsOTPValidated,
  clearSelectedKeys,
  getIsOtpSent,
  getRegisteredNo,
  getShipmentStatus,
  setButtonName,
  getAttemptCount,
} from './SessionStorage';
import ChatComponent from './ChatComponent';
import { authenticateWithKeycloak } from './utils/keycloak';
import {
  StyledChatbotContainer,
  StyledChatbotHeader,
  StyledPopUpOverLay,
  StyledWidgetMsg,
  StyledWidgetNotification,
  StyledWidgetPopImg,
} from './ChatBot.Styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBot } from '../../store/slices/chatbotSlice';
import { DEVICES } from '../../config/appConfig';
const { constants } = require('./config/constants');

const additionalOptions = constants.prompt_of_additional_options;
const complaintConfirmation = constants.prompt_of_complaint_confirmation;
const requestButtons = constants.request_buttons;
const complaintButtons = constants.complaint_buttons;
const otpKey = constants.prompt_of_otp;
const promptOfNumber = constants.prompt_of_number;
const invalidNoPrompt = constants.prompt_of_invalid_number;
const clearSessionKeys = constants.session_keys;
const statusButtons = constants.status_buttons;
const additionalButtons = constants.additionalButtons;

const FUTURE_DATE_DELIVERY = constants.FUTURE_DATE_DELIVERY;
const FUTURE_PICKUP_DATE = constants.FUTURE_PICKUP_DATE;
const UPDATE_ALTERNATE_CONTACT = constants.UPDATE_ALTERNATE_CONTACT;
const SHIPMENT_NOT_RECEIVED = constants.SHIPMENT_NOT_RECEIVED;
const PRODUCT_NOT_PICKED = constants.PRODUCT_NOT_PICKED;

const Chatbot = () => {
  const dispatch = useDispatch();
  const [isClosed, setIsClosed] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [userInput, setUserInput] = useState('');

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [placeholder, setPlaceholder] = useState('Choose the relevant option');
  const [homeIcon, setHomeIcon] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const isMounted = useRef(false);
  const [showNotification, setShowNotification] = useState(false);
  const { isChatOpen } = useSelector((state) => state.chatbot);
  const [showBot, setShowBot] = useState(false);

  useEffect(() => {
    if (isChatOpen) {
      handleBot();
    }
  }, [isChatOpen]);

  //To show and hide robot notification
  useEffect(() => {
    // Show the notification and play the sound after 2 seconds
    const showTimer = setTimeout(() => {
      setShowNotification(true);
    }, 2000);

    // Hide the notification after 5 seconds
    const hideTimer = setTimeout(() => {
      setShowNotification(false);
    }, 7000);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  useEffect(() => {
    const isMobileOrTablet =
      window.matchMedia(DEVICES.sMobile).matches ||
      window.matchMedia(DEVICES.mobile).matches ||
      window.matchMedia(DEVICES.tablet).matches;

    if (showBot && isMobileOrTablet) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => document.body.classList.remove('no-scroll');
  }, [showBot]);

  useEffect(() => {
    if (!isMounted.current) {
      authenticateWithKeycloak();
      fetchHomeButtons();
      clearSessionStorage();
      isMounted.current = true;
    }
  }, [setSelectedMessages]);

  const fetchHomeButtons = async () => {
    try {
      const response = await fetchHomeButtonsOptions();
      setSelectedMessages((prevMessages) => [...prevMessages, response]);
    } catch (error) {
      console.error('Error fetching home buttons:', error);
    }
  };

  const handleClose = () => {
    setShowPopup(true);
  };

  const handleConfirmExit = () => {
    setShowBot(false);
    dispatch(toggleBot(false));
    setSelectedMessages([]);
    fetchHomeButtons();
    setShowPopup(false);
    clearSessionStorage();
  };

  const handleCancelExit = () => {
    setShowPopup(false);
    setShowBot(true);
  };

  const handleMinimize = () => {
    setShowBot(false);
    dispatch(toggleBot(false));
  };

  const handleBot = () => {
    setShowBot(true);
    setShowInput(true);
    setIsClosed(false);
  };

  function returnTime() {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const extractShipmentId = (text) => {
    try {
      const match = text.match(/Shipment ID\s*:\s*(\S+)/);
      return match ? match[1] : null;
    } catch (error) {
      console.log('error in extractShipmentId', error);
      return null;
    }
  };

  const handleInputSubmit = async () => {
    try {
      if (userInput.trim()) {
        const currentFlow = getCurrentFlow();
        const isStatusButtons = statusButtons.includes(currentFlow);
        setSelectedMessages((prevMessages) => [
          ...prevMessages,
          { type: 'user', text: userInput, timestamp: returnTime() },
        ]);
        if (
          userInput.length == 10 ||
          (userInput.length == 12 && userInput.startsWith('91'))
        ) {
          const currentButton = getButtonName();
          const isOtpValid = getIsOTPValidated();
          if (currentButton === `${UPDATE_ALTERNATE_CONTACT} - YES`) {
            createUpdatTicket(userInput);
          } else if (isOtpValid == 'true') {
            const finalData = await fetchIntentsFromStorage(userInput);
            // console.log('finalData of OTP Validated', finalData);
            insertMessages(finalData);
            if (!Array.isArray(finalData))
              fetchResponses(additionalOptions, false);
          } else {
            sendOTPFunction(userInput);
            fetchResponses(otpKey, false);
          }
        } else if (userInput.length == 6) {
          // #features_pxb5559 changes done for future pickup and auto sent OTP
          const buttonName = getButtonName();
          const isOtpSent = getIsOtpSent();
          if (isOtpSent == 'true') {
            const resp = await validateOTPFunction(userInput);
            setUserInput('');
            await fetchResponses(resp, false);
            const isOtpValid = getIsOTPValidated();
            if (isOtpValid == 'true') {
              const finalData = await fetchIntentsFromStorage();
              insertMessages(finalData);
              if (finalData.includes('Sorry')) {
                // clearSelectedKeys(clearSessionKeys);
                fetchResponses(additionalOptions, false);
              } else if (finalData.includes('is not a')) {
                createTicket();
                fetchResponses(additionalOptions, false);
              } else if (
                currentFlow.includes('future date') ||
                currentFlow.includes('future pickup')
              ) {
                if (!Array.isArray(finalData))
                  fetchResponses(`${currentFlow} - confirmation`, true);
              } else if (getIntentTicket().includes('complaint')) {
                if (!Array.isArray(finalData))
                  fetchResponses(complaintConfirmation, false);
              } else if (currentFlow.includes('alternate')) {
                if (finalData && finalData.length > 0) {
                  if (!Array.isArray(finalData)) {
                    if (getNumber()) {
                      fetchResponses(`${currentFlow} - confirmation`, true);
                    } else {
                      fetchResponses(`${currentFlow} - shipment`, false);
                    }
                  }
                }
              } else if (getIntentName().includes('order-status')) {
                if (!Array.isArray(finalData)) {
                  createTicket();
                  fetchResponses(additionalOptions, false);
                }
              }
            }
          } else if (
            buttonName === `${UPDATE_ALTERNATE_CONTACT} - YES` ||
            buttonName === UPDATE_ALTERNATE_CONTACT
          ) {
            fetchResponses(invalidNoPrompt, false);
            setUserInput('');
            return;
          } else {
            //#features_pxb5559 changes done for auto sent OTP
            setAWBValue(userInput);
            let registeredNo = await validateShippingID(userInput);
            if (registeredNo && !isStatusButtons) {
              sendOTPFunction(registeredNo);
              fetchResponses(otpKey, false);
              setUserInput('');
            } else {
              fetchShipmentDetails(userInput);
            }
          }
        } else {
          // #enhancements_pxb5559 changes done according workflow enhancements
          const isOtpSent = getIsOtpSent();
          if (isOtpSent == 'true') {
            setUserInput('');
            fetchResponses('OTP-FAILED', false);
            return;
          }
          // #enhancements_pxb5559 changes done according workflow enhancements
          let isShipmentValid = await getShipmentTypeFromInput(userInput);
          if (isShipmentValid !== 'true') {
            insertMessages(isShipmentValid);
            setUserInput('');
            handlePlaceholderChange('');
            fetchHomeButtons();
            return;
          }
          const buttonName = getButtonName();
          if (buttonName === `${UPDATE_ALTERNATE_CONTACT} - YES`) {
            fetchResponses(invalidNoPrompt, false);
            setUserInput('');
            return;
          }
          const isOtpValid = getIsOTPValidated();
          if (
            (isOtpValid == 'false' || isOtpValid == '') &&
            (requestButtons.includes(currentFlow) ||
              complaintButtons.includes(currentFlow))
          ) {
            const getAWB = getAWBValue();
            // #features_pxb5559 changes done for future pickup and auto sent OTP
            if (getAWB == '') {
              let registeredNo = await validateShippingID(userInput);
              if (registeredNo && !isStatusButtons) {
                sendOTPFunction(registeredNo);
                fetchResponses(otpKey, false);
                setUserInput('');
                setAWBValue(userInput);
              } else {
                fetchShipmentDetails(userInput);
              }
              // setAWBValue(userInput);
            }
          } else {
            // #features_pxb5559 changes done for auto sent OTP
            setAWBValue(userInput);
            let registeredNo = await validateShippingID(userInput);
            if (registeredNo && !isStatusButtons) {
              sendOTPFunction(registeredNo);
              fetchResponses(otpKey, false);
              setUserInput('');
            } else {
              fetchShipmentDetails(userInput);
            }
          }
        }
        setUserInput('');
        handlePlaceholderChange('');
        setHomeIcon(true);
      }
    } catch (error) {
      console.log('Error handling input submit:', error);
    }
  };

  const handleButtonClick = async (message) => {
    try {
      const shipmentId = extractShipmentId(message);
      if (shipmentId) {
        message = `Shipment ID: ${shipmentId}`;
      }
      setSelectedMessages((prevMessages) => [
        ...prevMessages.filter(
          (msg) => !Array.isArray(msg) && msg.isType !== 'button'
        ),
        { type: 'user', text: message, timestamp: returnTime() },
      ]);
      setShowInput(true);
      handlePlaceholderChange('Select from the options or enter a value.');
      setHomeIcon(true);
      // #enhancements_pxb5559 changes done according workflow enhancements
      const currentFlow = getCurrentFlow();
      if (message == 'YES') {
        const isOtpValid = getIsOTPValidated();
        const shipmentStatus = getShipmentStatus();
        const attemptCount = getAttemptCount();
        // #enhancements_pxb5559 changes done according workflow enhancements
        if (isOtpValid == 'false') {
          const mobileNo = getNumber();
          if (mobileNo) {
            sendOTPFunction(mobileNo);
            fetchResponses(otpKey, false);
          } else {
            fetchResponses(promptOfNumber, false);
          }
        } else if (
          getIntentName().includes('complaint') &&
          currentFlow !== SHIPMENT_NOT_RECEIVED &&
          currentFlow !== PRODUCT_NOT_PICKED
        ) {
          createComplaintTicket();
        } else if (
          currentFlow === PRODUCT_NOT_PICKED &&
          ((shipmentStatus === 'Manifestation' && attemptCount === 0) ||
            shipmentStatus === 'OFP' ||
            shipmentStatus === 'DTO')
        ) {
          createComplaintTicket();
        } else if (isOtpValid == 'false') {
          const mobileNo = getNumber();
          if (mobileNo) {
            sendOTPFunction(mobileNo);
            fetchResponses(otpKey, false);
          } else {
            fetchResponses(promptOfNumber, false);
          }
        } else {
          // #enhancements_pxb5559 changes done according workflow enhancements
          let msg = message;
          if (
            currentFlow === SHIPMENT_NOT_RECEIVED &&
            (shipmentStatus === 'Manifestation' ||
              shipmentStatus === 'RTO' ||
              shipmentStatus === 'OFD' ||
              (shipmentStatus === 'Inscan' && attemptCount === 0))
          ) {
            setIntentValue('23');
            createComplaintTicket();
            return;
          } else if (
            (currentFlow === SHIPMENT_NOT_RECEIVED &&
              shipmentStatus === 'Delivered') ||
            (currentFlow === PRODUCT_NOT_PICKED &&
              shipmentStatus === 'PickedUp')
          ) {
            // let prompt = shipmentStatus === "Delivered" ? "Shipment is already delivered." : "Shipment is already picked. If you have any other concerns, feel free to let us know!";
            let ndrMessage = await getTicketDetails();
            if (ndrMessage && ndrMessage.length > 0 && ndrMessage[1] !== '') {
              insertMessages(ndrMessage[1]);
            }
            msg = shipmentStatus;
          } else if (
            currentFlow === SHIPMENT_NOT_RECEIVED &&
            shipmentStatus === 'Inscan' &&
            attemptCount >= 1
          ) {
            let ndrMessage = await getTicketDetails();
            if (ndrMessage && ndrMessage.length > 0 && ndrMessage[1] !== '') {
              insertMessages(ndrMessage[1]);
            }
            msg = 'NDR';
          } else if (
            currentFlow === PRODUCT_NOT_PICKED &&
            shipmentStatus === 'Manifestation' &&
            attemptCount >= 1
          ) {
            let nprMessage = await getTicketDetails();
            if (nprMessage && nprMessage.length > 0 && nprMessage[1] !== '') {
              insertMessages(nprMessage[1]);
            }
            msg = 'NPR';
          } else if (
            currentFlow === FUTURE_DATE_DELIVERY &&
            (((shipmentStatus === 'Manifestation' ||
              shipmentStatus === 'OFD' ||
              shipmentStatus === 'Inscan') &&
              attemptCount === 0) ||
              shipmentStatus === 'Delivered' ||
              shipmentStatus === 'RTO')
          ) {
            return createComplaintTicket();
          } else if (
            currentFlow === FUTURE_PICKUP_DATE &&
            (((shipmentStatus === 'Manifestation' ||
              shipmentStatus === 'Inscan' ||
              shipmentStatus === 'OFP') &&
              attemptCount === 0) ||
              shipmentStatus === 'PickedUp' ||
              shipmentStatus === 'DTO')
          ) {
            return createComplaintTicket();
          }
          fetchResponses(`${currentFlow} - ${msg}`, true);
        }
      } else if (message == 'NO') {
        fetchResponses(additionalOptions, false);
      } else if (shipmentId) {
        setAWBValue(shipmentId);
        const finalData = await fetchIntentsFromStorage(shipmentId);
        insertMessages(finalData);
        // #features_pxb5559 changes done for future pickup and auto sent OTP
        if (finalData && finalData.includes('Sorry!')) {
          // clearSelectedKeys(clearSessionKeys);
          fetchResponses(additionalOptions, false);
        } else if (getIntentName().includes('status')) {
          createTicket(shipmentId);
          fetchResponses(additionalOptions, false);
        } else if (finalData.includes('is not a')) {
          createTicket(shipmentId);
          fetchResponses(additionalOptions, false);
        } else if (getIntentName().includes('complaint')) {
          fetchResponses(complaintConfirmation, false);
        } else {
          const currentFlow = getCurrentFlow();
          fetchResponses(`${currentFlow} - confirmation`, true);
        }
      } else if (message == 'Surely not received.') {
        setIntentValue('23');
        createComplaintTicket();
      } else if (message == 'Request a new OTP') {
        const mobileNo = getNumber();
        if (mobileNo) {
          sendOTPFunction(mobileNo);
          fetchResponses(otpKey, false);
        } else {
          fetchResponses(promptOfNumber, false);
        }
      } else if (message == 'Go back to Home') {
        handleHomeButtonClick();
      } else {
        // #enhancements_pxb5559 changes done according workflow enhancements
        if (
          currentFlow === SHIPMENT_NOT_RECEIVED ||
          currentFlow === PRODUCT_NOT_PICKED
        ) {
          if (
            message ===
            'I do not agree with the reason of non delivery provided.'
          ) {
            // fake NDR
            setButtonName(message);
            setIntentValue('133');
            createComplaintTicket();
            return;
          } else if (message === 'Ok, deliver the shipment quickly.') {
            // log ticket with closed status
            setButtonName(message);
            setIntentValue('139');
            createComplaintTicket();
            return;
          } else if (message === 'Yes, I have received my shipment') {
            // log ticket with closed status
            setButtonName(message);
            createComplaintTicket();
            return;
          } else if (message === 'No, I have not received my shipment') {
            // fake delivery
            setButtonName(message);
            setIntentValue('134');
            createComplaintTicket();
            return;
          } else if (message === 'Yes, my return shipment is picked.') {
            // log ticket with closed status
            setButtonName(message);
            createComplaintTicket();
            return;
          } else if (message === 'No, the shipment is not yet picked.') {
            // fake pickup
            setButtonName(message);
            setIntentValue('120');
            createComplaintTicket();
            return;
          } else if (
            message ===
            'I do not agree with the reason for non-pickup provided.'
          ) {
            setButtonName(message);
            setIntentValue('119');
            createComplaintTicket();
            return;
          } else if (message === 'Ok, Kindly pick the shipment quickly.') {
            setButtonName(message);
            setIntentValue('131');
            createComplaintTicket();
            return;
          }
        }
        fetchResponses(message, true);
      }
    } catch (error) {
      console.log('Error handling button click:', error);
    }
  };

  const handleHomeButtonClick = () => {
    try {
      setSelectedMessages((prevMessages) => [
        ...prevMessages.filter((msg) => msg.isType !== 'button'),
      ]);
      fetchHomeButtons();
      clearSelectedKeys(clearSessionKeys);
      handlePlaceholderChange(
        'Click on the appropriate Quick Response button.'
      );
      setHomeIcon(false);
    } catch (error) {
      console.log('Error handling home button click:', error);
    }
  };

  const handlePlaceholderChange = (msg) => {
    setPlaceholder(msg);
  };

  const insertMessages = async (response) => {
    try {
      if (Array.isArray(response)) {
        if (response && response.length > 0) {
          for (let obj of response) {
            if (obj && obj.lastMsg) {
              fetchResponses(additionalOptions, false);
            } else {
              setSelectedMessages((prevMessages) => [
                ...prevMessages,
                {
                  type: 'server',
                  text: obj.content,
                  isType: obj.type,
                  timestamp: returnTime(),
                },
              ]);
            }
          }
        }
      } else if (response) {
        setSelectedMessages((prevMessages) => [
          ...prevMessages,
          {
            type: 'server',
            text: response,
            isType: 'text',
            timestamp: returnTime(),
          },
        ]);
      }
    } catch (error) {
      console.log('Error inserting messages:', error);
    }
  };

  const fetchResponses = async (input, flag) => {
    try {
      if (input === additionalButtons[0]) {
        fetchHomeButtons();
        return;
      }
      let response = await fetchOptionsOfInput(input, flag);
      // #features_pxb5559 changes done for future pickup
      if (
        (getButtonName() === `${FUTURE_DATE_DELIVERY} - YES` ||
          getButtonName() === `${FUTURE_PICKUP_DATE} - YES`) &&
        flag
      ) {
        response = await getFutureDeliveryDates(response);
      }
      insertMessages(response);
      if (additionalButtons.includes(input)) {
        clearSelectedKeys(clearSessionKeys);
      }
    } catch (error) {
      console.log('Error fetching responses:', error);
    }
  };

  const fetchShipmentDetails = async (shippingId) => {
    try {
      const response = await fetchIntentOfInput(shippingId, true);
      insertMessages(response);
      const currentFlow = getCurrentFlow();
      // #features_pxb5559 changes done for future pickup and auto sent OTP
      if (response && response.includes('Sorry!')) {
        // clearSelectedKeys(clearSessionKeys);
        fetchResponses(additionalOptions, false);
      } else if (
        currentFlow.includes('future date') ||
        currentFlow.includes('future pickup')
      ) {
        fetchResponses(`${currentFlow} - confirmation`, true);
      } else if (response.includes('is not a')) {
        createTicket(shippingId);
        fetchResponses(additionalOptions, false);
      } else if (getIntentTicket().includes('complaint')) {
        fetchResponses(complaintConfirmation, false);
      } else if (currentFlow.includes('alternate')) {
        if (response && response.length > 0) {
          if (getNumber()) {
            fetchResponses(`${currentFlow} - confirmation`, true);
          } else {
            fetchResponses(`${currentFlow} - shipment`, false);
          }
        }
      } else if (getIntentName().includes('order-status')) {
        createTicket(shippingId);
        fetchResponses(additionalOptions, false);
      } else {
        fetchResponses(additionalOptions, false);
      }
    } catch (error) {
      console.log('Error fetching shipment details:', error);
    }
  };

  const createTicket = async (shipmentId) => {
    try {
      if (!shipmentId) shipmentId = getAWBValue();
      const ticket = await createStatusTicket(shipmentId, getIntentTicket());
      clearSelectedKeys(clearSessionKeys);
    } catch (error) {
      console.log('Error creating ticket:', error);
    }
  };

  const createUpdatTicket = async (updatedValue) => {
    try {
      const ticket = await createUpdateTicket(updatedValue, getIntentTicket());
      // console.log('ticket update -->>>', ticket)
      clearSelectedKeys(clearSessionKeys);
      if (Array.isArray(ticket) && ticket.length != 0) {
        insertMessages(ticket);
      } else if (Array.isArray(ticket) && ticket.length == 0) {
        fetchResponses(additionalOptions, false);
      } else {
        insertMessages(ticket);
        fetchResponses(additionalOptions, false);
      }
    } catch (error) {
      console.log('Error creating update ticket:', error);
    }
  };

  const createComplaintTicket = async () => {
    try {
      let shipmentId = getAWBValue();
      const ticket = await createComplintTicket(shipmentId, getIntentTicket());
      clearSelectedKeys(clearSessionKeys);
      if (Array.isArray(ticket) && ticket.length != 0) {
        insertMessages(ticket);
      } else if (Array.isArray(ticket) && ticket.length == 0) {
        fetchResponses(additionalOptions, false);
      } else {
        insertMessages(ticket);
        fetchResponses(additionalOptions, false);
      }
    } catch (error) {
      console.log('Error creating complaint ticket:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleInputSubmit();
      handlePlaceholderChange('');
      setHomeIcon(true);
    }
  };

  const handleDateClick = (dateString) => {
    try {
      setSelectedMessages((prevMessages) => [
        ...prevMessages.filter(
          (msg) =>
            !Array.isArray(msg) &&
            msg.isType !== 'button' &&
            msg.isType !== 'date'
        ),
        { type: 'user', text: dateString, timestamp: returnTime() },
      ]);
      const [day, month, year] = dateString.split('-');
      const padDay = day.padStart(2, '0');
      const padMonth = month.padStart(2, '0');
      const formattedDate = `${year}-${padMonth}-${padDay}`;
      createUpdatTicket(formattedDate);
    } catch (error) {
      console.log('Error handling date click:', error);
    }
  };

  if (isClosed) return null;

  return (
    <>
      <StyledWidgetMsg id='widgetmsg' data-role='none'>
        {showNotification && (
          <StyledWidgetNotification
            data-role='none'
            id='pop'
            className='M16_42'
          >
            <div id='widgettxt' className='widgettxt'>
              <div id='widgetdesc'>
                Hi! I'm XBee.
                <br /> How may I assist you?
              </div>
            </div>
          </StyledWidgetNotification>
        )}

        <div data-role='none' id='launcher' className='launcher'>
          <button
            data-role='none'
            type='button'
            id='botwidget'
            className='botwidget'
            onClick={handleBot}
          >
            <StyledWidgetPopImg
              id='widgetpopimg'
              data-role='none'
              src='/images/chatbot/bcon.webp'
              alt='chatbot widget'
              fetchpriority='high'
              onMouseEnter={() => setShowNotification(true)} // Show notification on hover
              onMouseLeave={() => setShowNotification(false)}
            />
          </button>
        </div>
      </StyledWidgetMsg>

      {showBot && (
        <StyledChatbotContainer>
          <StyledChatbotHeader>
            <div className='header-text'>
              <h2>Hi! I'm XBee</h2>
              <p>Your personal virtual assistant</p>
            </div>
            <div className='icons'>
              <span className='minimize-icon' onClick={handleMinimize}>
                _
              </span>
              <span className='close-icon' onClick={handleClose}>
                ×
              </span>
            </div>
          </StyledChatbotHeader>
          {showPopup && (
            <StyledPopUpOverLay>
              <div className='popup-content'>
                <p>Closing this will end the chat.</p>
                <p>Are you sure you want to exit?</p>
                <div className='popup-buttons'>
                  <button
                    onClick={handleConfirmExit}
                    className='confirm-button'
                  >
                    OK
                  </button>
                  <button onClick={handleCancelExit} className='cancel-button'>
                    CANCEL
                  </button>
                </div>
              </div>
            </StyledPopUpOverLay>
          )}
          <ChatComponent
            selectedMessages={selectedMessages}
            handleButtonClick={handleButtonClick}
            handleInputSubmit={handleInputSubmit}
            showInput={showInput}
            placeholder={placeholder}
            homeIcon={homeIcon}
            handleHomeButtonClick={handleHomeButtonClick}
            handleKeyPress={handleKeyPress}
            setHomeIcon={setHomeIcon}
            userInput={userInput}
            setUserInput={setUserInput}
            setSelectedMessages={setSelectedMessages}
            handleDateClick={handleDateClick}
          />
        </StyledChatbotContainer>
      )}
    </>
  );
};

export default Chatbot;
