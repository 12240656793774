import React, { useState } from 'react';
import { TabContainer, TextStyle } from './Tab.styled';
import { useTranslation } from '../../../hooks/useTranslation';

const Tab = ({
  item = '',
  selectedIndex,
  onTabSelect = () => {},
  customStyle,
}) => {
  const t = useTranslation();
  return (
    <TabContainer>
      <TextStyle
        customStyle={customStyle}
        selectedIndex={selectedIndex}
        onClick={onTabSelect}
      >
        <div>{item}</div>
      </TextStyle>
    </TabContainer>
  );
};

export default Tab;
