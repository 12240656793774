import styled from 'styled-components';
import { DEVICES } from '../../../config/appConfig';

export const HeaderTitleContainer = styled.div`
  padding: 8px 0px;
  h2 {
    color: #010915;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
  }
  h2 span {
    color: #f58220;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
  }
  .subHeaderStyle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
  }

  .textColor {
    color: #010915bf;
  }

  .pipeColor {
    border-left: 1px solid #00000040;
    height: 6px;
    margin: 0px 8px;
  }

  @media ${DEVICES.sMobile} {
    max-width: 100%;
    h2 span {
      display: block;
    }
  }
`;
