import {
  getIntentName,
  getNumber,
  setAttemptCount,
  setBusinessType,
  setClientName,
  setDDT,
  setEDD,
  setIntentValue,
  setNDR,
  setNDRDate,
  setPddBreach,
  setReattemptDate,
  setShipmentStatus,
  setShipmentType,
  setWHHandOver,
} from '../SessionStorage';

const { fetchOptionsOfInput } = require('./OptionsHandler');
const { constants } = require('../config/constants.js');

const additionalOptions = constants.prompt_of_additional_options;

// #enhancements_pxb5559 changes done according workflow enhancements
const getCurrentDate = () => {
  let date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return new Date(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
};
// #enhancements_pxb5559 changes done according workflow enhancements
const getFutureDate = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const convertToISODate = (dateString) => {
  const [d, m, y, time] = dateString.replace(' ', '-').split('-');
  return new Date(`${y}-${m}-${d} ${time}`);
};

async function appendResponse(data) {
  try {
    // #enhancements_pxb5559 changes done according workflow enhancements
    let dateTime = getCurrentDate();
    let type = '';
    let pddDate, eddDate;
    for (let product = 0; product < data.length; product++) {
      // #features_pxb5559 changes done for future pickup
      if (
        data[product].businessType == 'forward' &&
        (getIntentName() == 'complaint-raise-wrong-picked' ||
          getIntentName() == 'complaint-raise-not-picked' ||
          getIntentName() == 'update-pickup-date')
      ) {
        return `${data[product].poid} is not a return request. Please select appropriate option.`;
      } else if (
        data[product].businessType == 'reverse' &&
        (getIntentName() == 'complaint-raise-not-received' ||
          getIntentName() == 'update-delivery-date' ||
          getIntentName() == 'complaint-raise-charged-extra')
      ) {
        return `${data[product].poid} is not a delivery request. Please select appropriate option.`;
      } else if (data[product].businessType == 'forward') {
        // #enhancements_pxb5559 changes done according workflow enhancements
        setBusinessType('forward');
        if (
          data[product].orderEDD != 'None' &&
          data[product].orderEDD != null
        ) {
          eddDate = convertToISODate(data[product].orderEDD);
          data[product].orderEDD = data[product].orderEDD.split(' ')[0];
          setEDD(data[product].orderEDD);
        }
        if (
          data[product].orderPDD != 'None' &&
          data[product].orderPDD != null
        ) {
          pddDate = convertToISODate(data[product].orderPDD);
          data[product].orderPDD = data[product].orderPDD.split(' ')[0];
          setEDD(data[product].orderPDD);
        }
        if (data[product].order_type == 'prepaid') {
          setShipmentType('prepaid');
          type = data[product].order_type;
        } else {
          setShipmentType('cod');
          type =
            data[product].order_type + ' of Rs. ' + data[product].orderAmount;
        }
        if (data[product].totalAttemptCount) {
          setAttemptCount(data[product].totalAttemptCount);
        }
        // #enhancements_pxb5559 changes done according workflow enhancements
        if (
          data[product].reattemptDate != 'None' &&
          data[product].reattemptDate != null
        ) {
          data[product].reattemptDate =
            data[product].reattemptDate.split(' ')[0];
          setReattemptDate(data[product].reattemptDate);
        }
        if (data[product].lastNDRDate && data[product].ndrCode) {
          setNDR(data[product].ndrCode);
          setNDRDate(data[product].lastNDRDate);
        }
        if (data[product].status == 'Pending') {
          setIntentValue('108');
          setShipmentStatus('Manifestation');
          setClientName(data[product].clientName);
          return (
            'The shipment with ID ' +
            data[product].poid +
            ' is a ' +
            type +
            ' order placed through ' +
            data[product].clientName +
            '. We will be able to confirm its estimated delivery date once it has been picked up from the vendor or warehouse. I kindly request you to check back after some time for an updated status. Thank you for your patience!'
          );
        } else if (
          (data[product].orderEDD == null ||
            data[product].orderEDD == 'None') &&
          (data[product].orderPDD == null || data[product].orderPDD == 'None')
        ) {
          setIntentValue('105');
          setShipmentStatus('Inscan');
          setClientName(data[product].clientName);
          if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            setShipmentStatus('OFD');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We apologise. Currently, it is in transit and has not reached its destination yet. We will try to deliver it ASAP.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            setShipmentStatus('OFD');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. It is out for delivery today. Our service representative will contact you shortly to ensure smooth delivery.'
            );
          } else if (
            data[product].status === 'Shipped' &&
            data[product].orderStatus === 'InScan' &&
            data[product].notificationType === 'CSAW' &&
            data[product].notificationStatus === 'Pending' &&
            data[product].totalAttemptCount >= 1
          ) {
            // V NDR
            return `The shipment with ID ${data[product].poid} is a ${type} order placed through ${data[product].clientName}. We were unable to deliver it due to ${data[product].ndrCode} on ${data[product].lastNDRDate}. We are currently awaiting further instructions from the client. Once we receive the updated date of delivery, we will process the request and ensure it is delivered as soon as possible. Thank you for your patience and understanding!`;
          } else if (
            data[product].status === 'Shipped' &&
            data[product].orderStatus === 'InScan' &&
            data[product].notificationStatus === 'Reattempt' &&
            data[product].totalAttemptCount >= 1
          ) {
            // UN NDR
            return `The shipment with ID ${
              data[product].poid
            } is a ${type} order placed through ${
              data[product].clientName
            }. We were unable to deliver it due to ${
              data[product].ndrCode
            } on ${
              data[product].lastNDRDate
            }. We will reattempt delivery on ${getFutureDate()}. Thank you for your patience and understanding!`;
          } else if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO'
          ) {
            if (data[product].totalAttemptCount >= 1) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus != 'InScan'
          ) {
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. Currently it is in transit and has not reached destination. We will try to deliver it as soon as possible. You will be notified by SMS and a phone call once it is out for delivery.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].totalAttemptCount >= 1
          ) {
            if (
              data[product].reattemptDate != 'None' &&
              data[product].reattemptDate != null
            ) {
              let reattemptDate = data[product].reattemptDate.split(' ')[0];
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it by ' +
                reattemptDate +
                '.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            }
          }
          // #enhancements_pxb5559 changes done according workflow enhancements
          else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null) &&
            data[product].originHubID === data[product].currentHubID &&
            data[product].currentHubID === data[product].finalHubID
          ) {
            setIntentValue('1');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. It has already reached its destination. We will make every effort to deliver it ASAP.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null) &&
            data[product].originHubID !== data[product].currentHubID &&
            data[product].currentHubID !== data[product].finalHubID
          ) {
            setIntentValue('1');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. It is currently in transit. We will make every effort to deliver it ASAP.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null ||
              data[product].totalAttemptCount == 'None' ||
              data[product].totalAttemptCount == 'NULL')
          ) {
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We will try to deliver it as soon as possible. You will be notified by SMS and a phone call once its out for delivery.'
            );
          } else if (
            data[product].status == 'Delivered' &&
            data[product].orderStatus == 'Delivered'
          ) {
            setIntentValue('100');
            setShipmentStatus('Delivered');
            let deliveryDate = data[product].deliveryDateTime;
            setDDT(deliveryDate);
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We are pleased to inform you that it has been successfully delivered on ' +
              deliveryDate +
              '.'
            );
          } else if (data[product].status == 'RTO') {
            setIntentValue('2');
            setShipmentStatus('RTO');
            // #enhancements_pxb5559 changes done according workflow enhancements
            if (data[product].orderStatus == 'WHHandover') {
              setIntentValue('107');
              setWHHandOver('true');
              if (data[product].totalAttemptCount > 1) {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. It has been successfully returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              } else {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. It has been successfully returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              }
            } else {
              if (data[product].totalAttemptCount > 1) {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because ' +
                  data[product].ndrCode +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
                );
              } else {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
                );
              }
            }
          } else if (data[product].status == 'Lost') {
            setShipmentStatus('Lost');
            setIntentValue('4');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been lost in transit. Please contact client in case you have any queries.'
            );
          } else if (data[product].status == 'STD') {
            setShipmentStatus('STD');
            setIntentValue('3');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been damaged in transit. Please contact client in case you have any queries.'
            );
          }
        } else {
          // #enhancements_pxb5559 changes done according workflow enhancements
          setIntentValue('106');
          setShipmentStatus('Inscan');
          setClientName(data[product].clientName);
          if (
            data[product].orderPDD == 'None' ||
            data[product].orderPDD == null
          ) {
            data[product].orderPDD = data[product].orderEDD;
            setEDD(data[product].orderEDD);
            pddDate = eddDate;
          }
          setPddBreach(pddDate >= dateTime ? 'false' : 'true');
          if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            setShipmentStatus('OFD');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We apologise. Currently, it is in transit and has not reached its destination yet. We will try to deliver it by ' +
              data[product].orderPDD +
              '.'
            );
          }
          // #enhancements_pxb5559 changes done according workflow enhancements
          else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery' &&
            pddDate >= dateTime
          ) {
            setShipmentStatus('OFD');
            setIntentValue('1');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. It is out for delivery today. Our service representative will contact you shortly to ensure smooth delivery.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery' &&
            pddDate < dateTime
          ) {
            setShipmentStatus('OFD');
            setIntentValue('14');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We sincerely apologize for the delay. The estimated delivery date was ' +
              data[product].orderPDD +
              ',  but due to unforeseen circumstances, the shipment was delayed. Good news! It is out for delivery today. Our service representative will contact you shortly to ensure smooth delivery. Thank you for your understanding and patience!'
            );
          } else if (
            data[product].status === 'Shipped' &&
            data[product].orderStatus === 'InScan' &&
            data[product].notificationType === 'CSAW' &&
            data[product].notificationStatus === 'Pending' &&
            data[product].totalAttemptCount >= 1
          ) {
            // V NDR
            return `The shipment with ID ${data[product].poid} is a ${type} order placed through ${data[product].clientName}. We were unable to deliver it due to ${data[product].ndrCode} on ${data[product].lastNDRDate}. We are currently awaiting further instructions from the client. Once we receive the updated date of delivery, we will process the request and ensure it is delivered as soon as possible. Thank you for your patience and understanding!`;
          } else if (
            data[product].status === 'Shipped' &&
            data[product].orderStatus === 'InScan' &&
            data[product].notificationStatus === 'Reattempt' &&
            data[product].totalAttemptCount >= 1
          ) {
            // UN NDR
            if (
              data[product].reattemptDate == 'None' ||
              data[product].reattemptDate == null
            ) {
              return `The shipment with ID ${
                data[product].poid
              } is a ${type} order placed through ${
                data[product].clientName
              }. We were unable to deliver it due to ${
                data[product].ndrCode
              } on ${
                data[product].lastNDRDate
              }. We will reattempt delivery on ${getFutureDate()}. Thank you for your patience and understanding!`;
            } else {
              return `The shipment with ID ${data[product].poid} is a ${type} order placed through ${data[product].clientName}. We were unable to deliver it due to ${data[product].ndrCode} on ${data[product].lastNDRDate}. We will reattempt delivery on  ${data[product].reattemptDate}. Thank you for your patience and understanding!`;
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO'
          ) {
            setIntentValue('2');
            if (data[product].totalAttemptCount >= 1) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus != 'InScan' &&
            pddDate >= dateTime
          ) {
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Currently it is in transit and has not reached destination. We will try to deliver it ASAP.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Currently it is in transit and has not reached destination. We will try to deliver it by ' +
                data[product].orderPDD +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus != 'InScan' &&
            pddDate < dateTime
          ) {
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We sincerely apologize for the delay. Due to unforeseen circumstances, the shipment has been delayed. Rest assured, we are working to ensure it is delivered at the earliest possible time. Our support team will keep you updated on the progress. Thank you for your understanding and patience!'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We sincerely apologize for the delay. The estimated delivery date was ' +
                data[product].orderPDD +
                ', but due to unforeseen circumstances, the shipment has been delayed. Rest assured, we are working to ensure it is delivered at the earliest possible time. Our support team will keep you updated on the progress. Thank you for your understanding and patience!'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate >= dateTime &&
            data[product].totalAttemptCount >= 1
          ) {
            if (pddDate < dateTime) {
              var verb = 'was';
            }
            if (pddDate >= dateTime) {
              var verb = 'is';
            }
            if (
              data[product].reattemptDate != 'None' &&
              data[product].reattemptDate != null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Its estimated date of delivery ' +
                verb +
                ' ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it by ' +
                data[product].reattemptDate +
                '.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Its estimated date of delivery ' +
                verb +
                ' ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate >= dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null) &&
            data[product].currentHubID === data[product].finalHubID
          ) {
            setIntentValue('1');
            // #enhancements_pxb5559 changes done according workflow enhancements
            return `The shipment with ID ${data[product].poid} is a ${type} order placed through ${data[product].clientName}. Its estimated delivery date is ${data[product].orderPDD}, and it has already reached its destination. We are on track to deliver it by ${data[product].orderPDD}.`;
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate >= dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null) &&
            data[product].originHubID !== data[product].currentHubID &&
            data[product].currentHubID !== data[product].finalHubID
          ) {
            setIntentValue('1');
            return `The shipment with ID ${data[product].poid} is a ${type} order placed through ${data[product].clientName}. Its estimated delivery date is ${data[product].orderPDD}, and it is currently in transit. We will make every effort to ensure it is delivered by ${data[product].orderPDD}.`;
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate >= dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null)
          ) {
            setIntentValue('1');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. Its estimated date of delivery is ' +
              data[product].orderPDD +
              '. We will make every effort to deliver it by ' +
              data[product].orderPDD +
              '.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate < dateTime &&
            data[product].totalAttemptCount >= 1
          ) {
            setIntentValue('1');
            if (pddDate < dateTime) {
              var verb = 'was';
            }
            if (pddDate >= dateTime) {
              var verb = 'is';
            }
            if (
              data[product].reattemptDate == 'None' ||
              data[product].reattemptDate == null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Its estimated date of delivery ' +
                verb +
                ' ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it on ' +
                data[product].reattemptDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate >= dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null)
          ) {
            setIntentValue('1');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. Its estimated date of delivery is ' +
              data[product].orderPDD +
              '. We will make every effort to deliver it by ' +
              data[product].orderPDD +
              '.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate < dateTime &&
            data[product].totalAttemptCount >= 1
          ) {
            setIntentValue('1');
            if (
              data[product].reattemptDate == 'None' ||
              data[product].reattemptDate == null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it by ' +
                data[product].reattemptDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate < dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null) &&
            data[product].currentHubID === data[product].finalHubID
          ) {
            setIntentValue('14');
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We sincerely apologize for the delay. The estimated delivery date was ' +
                data[product].orderPDD +
                ' but due to unforeseen circumstances, the shipment has been delayed. Good news! The shipment has reached its destination, and we are working to ensure it is delivered at the earliest possible time. Our support team will keep you updated on the progress. Thank you for your understanding and patience!'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We sincerely apologize for the delay. The estimated delivery date was ' +
                data[product].orderPDD +
                ' but due to unforeseen circumstances, the shipment has been delayed. Good news! The shipment has reached its destination, we are working to ensure it is delivered at the earliest possible time. Our support team will keep you updated on the progress. Thank you for your understanding and patience!'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            pddDate < dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null)
          ) {
            setIntentValue('14');
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We sincerely apologize for the delay. The estimated delivery date was ' +
                data[product].orderPDD +
                ' but due to unforeseen circumstances, the shipment has been delayed. Rest assured, we are working to ensure it is delivered at the earliest possible time. Our support team will keep you updated on the progress. Thank you for your understanding and patience!'
              );
            } else {
              return (
                'The shipment with ID ' +
                data[product].poid +
                ' is a ' +
                type +
                ' order placed through ' +
                data[product].clientName +
                '. We sincerely apologize for the delay. The estimated delivery date was ' +
                data[product].orderPDD +
                ' but due to unforeseen circumstances, the shipment has been delayed. Rest assured, we are working to ensure it is delivered at the earliest possible time. Our support team will keep you updated on the progress. Thank you for your understanding and patience!'
              );
            }
          } else if (
            data[product].status == 'Delivered' &&
            data[product].orderStatus == 'Delivered'
          ) {
            setIntentValue('100');
            setShipmentStatus('Delivered');
            let deliveryDate = data[product].deliveryDateTime;
            setDDT(deliveryDate);
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We are pleased to inform you that it has been successfully delivered on ' +
              deliveryDate +
              '.'
            );
          } else if (data[product].status == 'RTO') {
            setShipmentStatus('RTO');
            if (data[product].orderStatus == 'WHHandover') {
              setIntentValue('107');
              setWHHandOver('true');
              if (data[product].totalAttemptCount > 1) {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because. It has been successfully returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              } else {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
                );
              }
            } else {
              setIntentValue('2');
              if (data[product].totalAttemptCount > 1) {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because ' +
                  data[product].ndrCode +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
                );
              } else {
                return (
                  'The shipment with ID ' +
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' order placed through ' +
                  data[product].clientName +
                  '. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly.'
                );
              }
            }
          } else if (data[product].status == 'Lost') {
            setShipmentStatus('RTO');
            setIntentValue('4');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been lost in transit. Please contact client in case you have any queries.'
            );
          } else if (data[product].status == 'STD') {
            setShipmentStatus('RTO');
            setIntentValue('3');
            return (
              'The shipment with ID ' +
              data[product].poid +
              ' is a ' +
              type +
              ' order placed through ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been damaged in transit. Please contact client in case you have any queries.'
            );
          } else {
            setIntentValue('99');
            return "Sorry! I couldn't find any shipments with the mentioned ID. \n Please enter a valid Shipment ID or your registered Mobile number.";
          }
        }
      } else if (data[product].businessType == 'reverse') {
        // #enhancements_pxb5559 changes done according workflow enhancements
        setBusinessType('reverse');
        setDDT(data[product].manifestDataReceived);
        setClientName(data[product].clientName);

        if (
          data[product].reattemptDate != 'None' &&
          data[product].reattemptDate != null
        ) {
          data[product].reattemptDate =
            data[product].reattemptDate.split(' ')[0];
          setReattemptDate(data[product].reattemptDate);
        }
        if (data[product].lastNDRDate && data[product].lastNDRUserProcess) {
          setNDR(data[product].lastNDRUserProcess);
          setNDRDate(data[product].lastNDRDate);
        }
        if (
          data[product].status == 'Pending' &&
          data[product].ndrCode == 'Out for PickUp'
        ) {
          setIntentValue('108');
          setShipmentStatus('OFP');
          return (
            'The pickup request for Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' on ' +
            data[product].manifestDataReceived +
            '. Our service representative is currently out for pickup and will contact you shortly. Thank you for your patience!'
          );
        } else if (
          data[product].status == 'Pending' &&
          data[product].orderStatus == 'PickupNotDone'
        ) {
          setIntentValue('109');
          setShipmentStatus('Manifestation');
          if (data[product].totalAttemptCount >= 1) {
            setAttemptCount('1');
            if (
              data[product].notificationType === 'CSAW' &&
              data[product].notificationStatus === 'Pending'
            ) {
              // V NDR
              return `The pickup request for Shipment ID ${data[product].poid} was initiated by ${data[product].clientName} on ${data[product].manifestDataReceived}. We attempted to pick up the product but were unable to proceed due to ${data[product].lastNDRUserProcess} on ${data[product].lastNDRDate}. We are currently awaiting further instructions from the client. Once we receive the updated pickup date, we will process the request and ensure the product is picked up as soon as possible. Thank you for your patience and understanding!`;
            } else if (
              data[product].reattemptDate == null ||
              data[product].reattemptDate == 'None'
            ) {
              return (
                'The pickup request for Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              if (eligibleDate == 'ASAP') {
                return `The pickup request for Shipment ID ${data[product].poid} was initiated by ${data[product].clientName} on ${data[product].manifestDataReceived}. We attempted to pick up the product but were unable to proceed due to ${data[product].lastNDRUserProcess} on ${data[product].lastNDRDate}. We will try to pick up the product as soon as possible. You will be notified via SMS and a phone call once the pickup is scheduled. Thank you for your patience and understanding!`;
              } else {
                return `The pickup request for Shipment ID ${data[product].poid} was initiated by ${data[product].clientName} on ${data[product].manifestDataReceived}. We attempted to pick up the product but were unable to proceed due to ${data[product].lastNDRUserProcess} on ${data[product].lastNDRDate}. We will try to pick up the product it on ${eligibleDate}. Thank you for your patience and understanding!`;
              }
            }
          } else {
            if (
              data[product].manifestDataReceived == null ||
              data[product].manifestDataReceived == 'None'
            ) {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              return (
                'The pickup request for Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitmanifestDate = await splitDate(
                data[product].manifestDataReceived
              );
              let eligibleDate = await getEligiblityDate(
                splitmanifestDate,
                'Manifest'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'The pickup request for Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will try to pick up the product ' +
                  eligibleDate +
                  '.'
                );
              } else {
                return (
                  'The pickup request for Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will try to pick up the product on ' +
                  eligibleDate +
                  '.'
                );
              }
            }
          }
        }
        // #enhancements_pxb5559 changes done according workflow enhancements
        else if (
          data[product].status == 'Pending' &&
          (data[product].ndrCode === 'pick up done' ||
            data[product].ndrCode === 'Pick up done')
        ) {
          setIntentValue('111');
          setShipmentStatus('PickedUp');
          return (
            'The pickup request for Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            '. We successfully picked up the product on ' +
            data[product].manifestDataReceived +
            ', and it will be returned to its origin very soon.'
          );
        } else if (data[product].status == 'Pending') {
          setIntentValue('108');
          setShipmentStatus('Manifestation');
          if (data[product].totalAttemptCount >= 1) {
            setAttemptCount('1');
            if (
              data[product].reattemptDate == null ||
              data[product].reattemptDate == 'None'
            ) {
              return (
                'The pickup request for Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'The pickup request for Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We tried but could not pick the product because ' +
                  data[product].lastNDRUserProcess +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. We will reattempt to pick it ' +
                  eligibleDate +
                  '.'
                );
              } else {
                return (
                  'The pickup request for Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We tried but could not pick the product because ' +
                  data[product].lastNDRUserProcess +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. We will reattempt to pick it on ' +
                  eligibleDate +
                  '.'
                );
              }
            }
          } else {
            if (
              data[product].manifestDataReceived == null ||
              data[product].manifestDataReceived == 'None'
            ) {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              return (
                'The pickup request for Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitmanifestDate = await splitDate(
                data[product].manifestDataReceived
              );
              let eligibleDate = await getEligiblityDate(
                splitmanifestDate,
                'Manifest'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'The pickup request for Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will attempt to pick up the product ' +
                  eligibleDate +
                  '. Thank you for your patience!'
                );
              } else {
                return (
                  'The pickup request for Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will attempt to pick up the product on ' +
                  eligibleDate +
                  '. Thank you for your patience!'
                );
              }
            }
          }
        } else if (data[product].orderStatus == 'RPCancel') {
          setIntentValue('110');
          if (data[product].totalAttemptCount >= 1) {
            return (
              'The pickup request for Shipment ID ' +
              data[product].poid +
              ' was initiated by ' +
              data[product].clientName +
              ' on ' +
              data[product].manifestDataReceived +
              '. We tried but could not pick the product because ' +
              data[product].lastNDRUserProcess +
              ' on ' +
              data[product].lastNDRDate +
              '. The pickup request has been cancelled as per instructions by the client. Please contact them in case you have any queries.'
            );
          } else {
            return (
              'The pickup request for Shipment ID ' +
              data[product].poid +
              ' was initiated by ' +
              data[product].clientName +
              ' on ' +
              data[product].manifestDataReceived +
              '. The pickup request has been cancelled as per instructions by the client. Please contact them in case you have any queries.'
            );
          }
        } else if (data[product].orderStatus == 'PickupDone') {
          setIntentValue('111');
          setShipmentStatus('PickedUp');
          setDDT(data[product].manifestDataReceived);
          return (
            'The pickup request for Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' We successfully picked up the product on ' +
            data[product].manifestDataReceived +
            ', and it will be returned to its origin very soon.'
          );
        }
        // #enhancements_pxb5559 changes done according workflow enhancements
        else if (
          data[product].status === 'DTO' &&
          data[product].orderStatus === 'WHHandover'
        ) {
          setIntentValue('113');
          setShipmentStatus('DTO');
          setWHHandOver('true');
          setDDT(data[product].manifestDataReceived);
          return `The pickup request for Shipment ID ${data[product].poid} was initiated by ${data[product].clientName}. We have successfully picked up the product on ${data[product].manifestDataReceived}, and it will be delivered to its origin very soon. For any queries, please feel free to contact ${data[product].clientName} directly. Thank you for your understanding!`;
        } else if (data[product].status == 'DTO') {
          setIntentValue('113');
          setShipmentStatus('DTO');
          setDDT(data[product].manifestDataReceived);
          return `The pickup request for Shipment ID ${data[product].poid} was initiated by ${data[product].clientName}. We have successfully picked up the product on ${data[product].manifestDataReceived}, and it will be delivered to its origin very soon. For any queries, please feel free to contact ${data[product].clientName} directly. Thank you for your understanding!`;
        } else if (data[product].status == 'Shipped') {
          setIntentValue('111');
          setShipmentStatus('DTO');
          setDDT(data[product].manifestDataReceived);
          return `The pickup request for Shipment ID ${data[product].poid} was initiated by ${data[product].clientName}. We have successfully picked up the product on ${data[product].manifestDataReceived}, and it will be delivered to its origin very soon. For any queries, please feel free to contact ${data[product].clientName} directly. Thank you for your understanding!`;
        } else {
          setIntentValue('99');
          return "Sorry! I couldn't find any shipments with the mentioned ID. \n Please enter a valid Shipment ID or your registered Mobile number.";
        }
      }
    }
  } catch (error) {
    return 'No data found';
  }
}

async function eligibleDateFormat(date) {
  try {
    var day = date;
    var dt =
      day.getDate() + '-' + (day.getMonth() + 1) + '-' + day.getFullYear();
    return dt;
  } catch (error) {
    console.log('Error in function eligibleDateFormat', error);
  }
}

async function setDateHMS(h, m, s, date) {
  date.setHours(h);
  date.setMinutes(m);
  date.setSeconds(s);
  return date;
}

async function getEligiblityDate(date, flag) {
  try {
    if (flag == 'Manifest') {
      let date1 = new Date();
      date1.setDate(date1.getDate(date1) - 1);
      date1 = await setDateHMS(13, 20, 0, date1);
      let date2 = new Date();
      date2 = await setDateHMS(7, 49, 59, date2);
      let date3 = new Date();
      date3 = await setDateHMS(7, 50, 0, date3);
      let date4 = new Date();
      date4 = await setDateHMS(10, 29, 59, date4);
      let date5 = new Date();
      date5 = await setDateHMS(10, 30, 0, date5);
      let date6 = new Date();
      date6 = await setDateHMS(13, 19, 59, date6);
      if (date < date1) {
        let eligibleDate = 'ASAP';
        return eligibleDate;
      } else if (date > new Date()) {
        let eligibleDate = await eligibleDateFormat(date);
        return eligibleDate;
      } else if (date > date1 && date < date2) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date3 && date < date4) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date5 && date < date6) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date6) {
        let eligibleDate = new Date();
        eligibleDate.setDate(eligibleDate.getDate() + 1);
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      }
    } else {
      let date1 = new Date();
      date1.setDate(date1.getDate(date1) - 1);
      date1 = await setDateHMS(16, 0, 0, date1);
      let date2 = new Date();
      date2 = await setDateHMS(7, 49, 59, date2);
      let date3 = new Date();
      date3 = await setDateHMS(7, 50, 0, date3);
      let date4 = new Date();
      date4 = await setDateHMS(10, 29, 59, date4);
      let date5 = new Date();
      date5 = await setDateHMS(10, 30, 0, date5);
      let date6 = new Date();
      date6 = await setDateHMS(13, 19, 59, date6);
      let date7 = new Date();
      date7 = await setDateHMS(13, 20, 0, date7);
      let date8 = new Date();
      date8 = await setDateHMS(15, 59, 59, date8);
      if (date < date1) {
        let eligibleDate = 'ASAP';
        return eligibleDate;
      } else if (date > new Date()) {
        let eligibleDate = await eligibleDateFormat(date);
        return eligibleDate;
      } else if (date > date1 && date < date2) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date3 && date < date4) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date5 && date < date6) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date7 && date < date8) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      }
    }
  } catch (error) {
    console.log('Error in function getEligiblityDate', error);
  }
}

async function splitDate(date) {
  try {
    let day = date;
    let dateSplit = day.split(' ');
    let dd = dateSplit[0].split('-', 1)[0];
    let mm = dateSplit[0].split('-', 2)[1];
    mm = mm - 1;
    let yy = dateSplit[0].split('-')[2];

    let finalDate = new Date(yy, mm, dd);
    return finalDate;
  } catch (error) {
    console.log('Error in function splitDate', error);
  }
}

function returnTime() {
  var date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

async function appendordercard_list(data) {
  try {
    let responseArray = [];
    // #features_pxb5559 prompt change based on input
    if (data && data.length == 0) {
      let registeredNo = getNumber();
      if (!registeredNo)
        return "Sorry! I couldn't find any shipments with the mentioned ID. \n Please enter a valid Shipment ID or your registered Mobile number.";
      else
        return "Sorry! I couldn't find any shipments with the mentioned ID. \n Please enter a valid Shipment ID or your registered Mobile number.";
    }
    let shipmentsType =
      data && data.length > 1 ? 'Multiple Shipments' : 'Single Shipment';
    const respArr = await fetchOptionsOfInput(shipmentsType, false);
    if (respArr.length) {
      for (let obj of respArr) {
        let Obj = {
          type: 'text',
          content: obj.content,
          timestamp: returnTime(),
        };
        responseArray.push(Obj);
      }
    }
    for (let product = 0; product < data.length; product++) {
      let obj = {
        type: 'button',
        content: `Shipment ID : ${data[product].shipment_id}
                            Status : ${data[product].shipment_status}`,
      };
      responseArray.push(obj);
    }
    return responseArray;
  } catch (error) {
    return [];
  }
}

export async function dataReceived(data) {
  try {
    for (let element = 0; element < data.response.length; element++) {
      if (element == 0) {
        if (element == data.response.length - 1) {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            let msg = await appendResponse(data.response[element]['orderCard']);
            return msg;
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        } else {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            let msg = await appendResponse(data.response[element]['orderCard']);
            return msg;
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        }
      } else if (element > 0) {
        if (element == data.response.length - 1) {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            let msg = await appendResponse(data.response[element]['orderCard']);
            return msg;
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        } else {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            return await appendResponse(data.response[element]['orderCard']);
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        }
      }
    }
  } catch (error) {
    console.log('error in function dataReceived', error);
    return 'No data found catch';
  }
}
