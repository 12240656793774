import React from 'react';
import { CustomTextArea, TextAreaContainer } from './TextArea.styled';

const TextArea = ({
  name,
  value,
  placeholder,
  onChange,
  rows = 4,
  cols = 30,
  customStyle,
}) => {
  return (
    <TextAreaContainer customStyle={customStyle}>
      <CustomTextArea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        cols={cols}
      />
    </TextAreaContainer>
  );
};

export default TextArea;
