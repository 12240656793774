import React from 'react';
import {
  BottomImageWrapper,
  ImageLeft,
  ImageRight,
} from './FormBottomSVG.styled';

const FormBottomSVG = () => {
  return (
    <BottomImageWrapper>
      <ImageLeft src='/images/BottomLeftSvg.svg' alt='Left Image' />
      <ImageRight src='/images/BottomRightSvg.svg' alt='Right Image' />
    </BottomImageWrapper>
  );
};

export default FormBottomSVG;
