import { BiMap, BiTimeFive } from 'react-icons/bi';
import { FiPackage, FiPhoneCall } from 'react-icons/fi';
import { MdHistory } from 'react-icons/md';
import { TbClipboardList, TbTruckDelivery } from 'react-icons/tb';
import { FaHandPointer, FaRegCopy } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

export const icons = {
  orderIcon: <FiPackage />,
  orderOutForDeliveryIcon: <TbTruckDelivery />,
  mapIcon: <BiMap />,
  orderInfoIcon: <TbClipboardList />,
  timeIcon: <BiTimeFive />,
  historyIcon: <MdHistory />,
  copyIcon: <FaRegCopy />,
  phoneCallIcon: <FiPhoneCall />,
  handPointerIcon: <FaHandPointer />,
  downArrow: <IoIosArrowDown />,
};
