import styled, { createGlobalStyle } from 'styled-components';
import { DEVICES } from '../config/appConfig';
export default createGlobalStyle`

  #root {
    min-height: 100vh;
    width: 100%;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }
  .selected{border-color: #f88f1f !important;
  background: #fff !important;
  a{box-shadow: 0px 2px 0px #ac5407 !important;
    background: rgb(247, 148, 29) !important;
    color: #fff !important;}
  }
  body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    &.no-scroll {
      overflow: hidden;
    }
  }
  a:focus, .navbar-toggler:focus{outline: none !important;box-shadow: 0 0 0 0 !important;}
button:focus:not(:focus-visible){outline: none !important;}
  .insideHeader{background:#fff;color:#000;}
  .w-100 {
    width: 100%!important;
}
.g-recaptcha{
transform:scale(0.77);
-webkit-transform:scale(0.77);
transform-origin:0 0;
-webkit-transform-origin:0 0;
}
.title {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  display:flex;align-items: center; justify-content: center;
}
.title img.titleLogo {margin-right:5px;}
.title span {
  color: #f58220;
}
.tabActive{
  background-color:rgb(245, 130, 32) !important;
  color: #ffff !important;
}
.stickyBtn {
  button{
  position: fixed;
  top: 25%;
  right:0px;
  box-shadow: 0px 2px 0px #ac5407;
  background: #f58220 !important;
  padding: 5px 10px !important;
  border-radius: 5px;
  border-color: rgb(247, 148, 29);
  color:#fff;
  transform: rotate(90deg);
  right: -28px;
  z-index:1;
  font-size:14px;
}
}
.enquiryBtn {
  position: relative;
  button{
  position: fixed;
  top: 45%;
  box-shadow: 0px 2px 0px #ac5407;
  background: #f58220 !important;
  padding: 5px 10px !important;
  border-radius: 5px;
  border-color: rgb(247, 148, 29);
  color:#fff;
  transform: rotate(90deg);
  z-index:1;
  right:-39px;
  font-size:14px;
}
}
.text-center{text-align:center;}
.text-left{text-align:left;}
.text-right{text-align:right;}

.blackBtnUnderline {
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  text-decoration: none;
  display: inline-block;margin:10px 0px;
}.gTitle {
  font-family: Rubik, sans-serif;
font-weight: 500;
font-size: 24px;
text-align: center;
margin-bottom: 20px;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: left;
}
  .TitleLogo{width:370px;margin-left:10px; margin-right:10px;}
  .GlobalContainer{max-width:1140px;width:100%;display:flow-root;margin:0 auto;padding:25px 15px;}
  .globalContainer.text-center{text-align:center !important;}
  .globalContainer.text-center{text-align:center !important;}
.GlobalButton{
  box-shadow: 0px 2px 0px #ac5407;
  background: rgb(247, 148, 29);
  padding: 10px 15px;color:#fff;
  border-radius: 5px;
  border-color: rgb(247, 148, 29);
  
}
.TrackShipmentHeader{padding-top: 100px;padding-bottom: 50px;}
  @media ${DEVICES.tablet} {.title {font-size:34px;}}
  @media ${DEVICES.mobile} {
    .title {font-size:34px;}
    .TitleLogo{width:213px;}
     
}
@media ${DEVICES.sMobile} {
  .title {font-size:24px;padding-left:10px;padding-right:10px;}
  .text-center{text-align:right !important;padding-right:10px;}
  .stickyBtn{
    button{top: 25%;box-shadow: none;padding:2px 8px 4px 8px;font-size:14px;}
  }
  .support{padding-top:40px;padding-bottom:40px;
    .text-center{text-align:center !important;}
  }
  .globalContainer.text-center{text-align:center !important;}
} 
`;

export const StyledLayoutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  flex-direction: row;
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledContent = styled.div`
  min-height: 450px;
  overflow: hidden;
`;
