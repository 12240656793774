import { useTranslation } from '../../hooks/useTranslation';
import { icons } from '../../icons/icons';
import { Checkmark } from './BusinessEnquireForm.styled';

const Card = ({
  card,
  selected,
  onClick,
  isFranchise = false,
  benefits,
  eligibilitys,
  showViewToggle,
  toglleViewAll,
}) => {
  const t = useTranslation();
  return (
    <div
      className={`form-card ${selected === card?.id ? 'selected-card' : ''}`}
      onClick={() => onClick(card?.id)}
    >
      <div className='card-header'>
        <img src={card?.image} alt={card?.title} />
        <div>
          <h3 className='card-title'>{card?.title}</h3>
          <p className='card-description'>{card?.description}</p>
        </div>
      </div>

      {isFranchise && (
        <>
          <div className='card-content'>
            {benefits && (
              <div className='benefits'>
                <h3>Benefits</h3>
                <ul>
                  {benefits
                    .slice(
                      0,
                      showViewToggle[`${card?.id}_benefit`]
                        ? benefits?.length
                        : 2
                    )
                    .map((benefit) => (
                      <li key={benefit}>
                        <img
                          src='/images/enquiryForm/circle_tick.svg'
                          alt='tick'
                        />
                        <span>{benefit}</span>
                      </li>
                    ))}
                  {benefits.length > 2 && (
                    <button
                      className={`show-more-btn ${
                        showViewToggle[`${card?.id}_benefit`] ? 'selected' : ''
                      }`}
                      data-testid={`${card?.id}_benefit`}
                      onClick={() => toglleViewAll(`${card?.id}_benefit`)}
                    >
                      {showViewToggle[`${card?.id}_benefit`]
                        ? t('app.VIEW_LESS')
                        : t('app.VIEW_ALL')}
                      {icons.downArrow}
                    </button>
                  )}
                </ul>
              </div>
            )}

            {eligibilitys && (
              <div className='eligibility'>
                <h3>Eligibility</h3>
                <ul>
                  {eligibilitys
                    .slice(
                      0,
                      showViewToggle[`${card?.id}_eligibility`]
                        ? eligibilitys.length
                        : 2
                    )
                    .map((item) => (
                      <li key={item}>
                        <img
                          src='/images/enquiryForm/circle_tick.svg'
                          alt='tick'
                        />
                        <span>{item}</span>
                      </li>
                    ))}
                  {eligibilitys.length > 2 && (
                    <button
                      className={`show-more-btn ${
                        showViewToggle[`${card?.id}_eligibility`]
                          ? 'selected'
                          : ''
                      }`}
                      data-testid={`${card?.id}_eligibility`}
                      onClick={() => toglleViewAll(`${card?.id}_eligibility`)}
                    >
                      {showViewToggle[`${card?.id}_eligibility`]
                        ? t('app.VIEW_LESS')
                        : t('app.VIEW_ALL')}
                      {icons.downArrow}
                    </button>
                  )}
                </ul>
              </div>
            )}
          </div>
        </>
      )}

      <div className='checkbox'>
        <input
          type='checkbox'
          name='card'
          checked={selected === card?.id}
          onChange={() => onClick(card?.id)}
        />
        <Checkmark checked={selected === card?.id} />
      </div>
    </div>
  );
};
export default Card;
