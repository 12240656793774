import { constants } from '../config/constants';
const { dataReceived } = require('./EnquiryHandler');
const {
  setIntentName,
  getIntentName,
  getButtonName,
  setButtonName,
  setIntentTicket,
  getIntentTicket,
  setCurrentFlow,
  getCurrentFlow,
  getNumber,
  setNumber,
  getIntentValue,
  setIntentValue,
  setIsOTPValidated,
  getAWBValue,
  setIsOtpSent,
  setRegisteredNo,
  getShipmentStatus,
  getAttemptCount,
  getEDD,
  getShipmentType,
  getBusinessType,
  getPddBreach,
  getDDT,
  getClientName,
  getReattemptDate,
  getNDR,
  getNDRDate,
  getWHHandOver,
} = require('../SessionStorage');
const {
  fetchHeaders,
  createPayload,
  createOTPPayload,
  createIntentPayload,
  createStatusPayload,
  createRequestPayload,
  createTicketPayload,
  createAwbDetailsPayload,
  createDelayPayload,
} = require('../utils/payloads');

const fetchRecordsPrompt = constants.prompt_of_fetch_records;
const fetchRecordsOtpPrompt = constants.prompt_of_fetch_records_otp;
const statusButtons = constants.status_buttons;
const rvpButtons = constants.rvp_buttons;
const fwdButtons = constants.fwd_buttons;
// #enhancements_pxb5559 changes done according workflow enhancements
const DELIVERY_DATE_ENQUIRY = constants.DELIVERY_DATE_ENQUIRY;
const FUTURE_DATE_DELIVERY = constants.FUTURE_DATE_DELIVERY;
const FUTURE_PICKUP_DATE = constants.FUTURE_PICKUP_DATE;
const UPDATE_ALTERNATE_CONTACT = constants.UPDATE_ALTERNATE_CONTACT;
const SHIPMENT_NOT_RECEIVED = constants.SHIPMENT_NOT_RECEIVED;
const MISBEHAVIOR_BY_SR = constants.MISBEHAVIOR_BY_SR;
const CHARGED_EXTRA_AMOUNT = constants.CHARGED_EXTRA_AMOUNT;
const WRONG_PRODUCT_PICKED = constants.WRONG_PRODUCT_PICKED;
const PRODUCT_NOT_PICKED = constants.PRODUCT_NOT_PICKED;
const PRODUCT_DAMAGED = constants.PRODUCT_DAMAGED;
const PRODUCT_MISSING = constants.PRODUCT_MISSING;
const HOME_BUTTONS = constants.home_buttons;
const MIS_DAM_PRODUCT = constants.MIS_DAM_PRODUCT;

const ChatBotApiUrl = process.env.REACT_APP_CHATBOT_API;
export const fetchHomeButtonsOptions = async () => {
  try {
    const payload = await createPayload('Home', fetchRecordsPrompt);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (result && result.response && result.response.length > 0) {
      if (result.response[0].buttons) {
        return result.response[0].buttons;
      } else if (result.response[0].text) {
        return [];
      }
    }
    return [];
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
};

export async function fetchOptionsOfInput(input, flag) {
  try {
    if (flag) {
      setButtonName(input);
      fetchIntentOfInput(input, false);
    }
    const payload = await createPayload(input, fetchRecordsPrompt);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (result && result.response && result.response.length > 0) {
      if (result.response[0].buttons) {
        return result.response[0].buttons;
      } else if (result.response[0].text) {
        return [];
      }
    }
    return [];
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
}

export const sendOTPFunction = async (mobileNo) => {
  try {
    setNumber(mobileNo);
    const payload = await createPayload(mobileNo, fetchRecordsOtpPrompt);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    setIsOtpSent(true);
    return;
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
};

export const validateOTPFunction = async (otp) => {
  try {
    const sentNumber = getNumber();
    const payload = await createOTPPayload(otp, sentNumber);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();

    if (result && result.response && result.response.length > 0) {
      let msg = result.response[0].text;
      setIsOTPValidated(false);
      if (msg == 'OTP verified successfully') {
        setIsOTPValidated(true);
        setIsOtpSent(false);
        return 'OTP-SUCCESS';
      } else if (msg == 'OTP has expired') {
        return 'OTP-EXPIRED';
      } else if (msg == 'Invalid OTP') {
        return 'OTP-FAILED';
      } else {
        return 'OTP-NO';
      }
    }
    return 'OTP-NO';
  } catch (error) {
    return 'OTP-NO';
  }
};

export const fetchIntentsFromStorage = async (shippingId) => {
  let data = {
    intent_name: getIntentName() ? getIntentName() : 'order-status',
    intent_ticket: getIntentTicket()
      ? getIntentTicket()
      : 'order-status-ticket',
    type: getCurrentFlow() ? getCurrentFlow() : 'default',
  };
  let mobileNo = shippingId
    ? shippingId
    : getAWBValue()
    ? getAWBValue()
    : getNumber();
  let resp = await fetchIntentsResponse(data, mobileNo);
  return resp;
};

export const fetchIntentsResponse = async (data, shippingId) => {
  try {
    setIntentName(data['intent_name']);
    setCurrentFlow(data['type']);
    if (data['intent_name']) {
      setIntentTicket(data['intent_ticket']);
      const payload = await createStatusPayload(
        shippingId,
        data['intent_name']
      );
      const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let result = await response.json();
      // console.log('result -->>', result);
      return await dataReceived(result);
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error sending fetchIntentOfInput:', error);
    return [];
  }
};

export const fetchIntentOfInput = async (shippingId, flag) => {
  try {
    // #features_pxb5559 added additional checking
    shippingId = shippingId ? shippingId : getAWBValue();
    const currentButtonValue = getButtonName();
    const currentFlow = getCurrentFlow();
    if (currentButtonValue) {
      const payload = await createIntentPayload(
        currentButtonValue,
        'fetch-intents'
      );
      const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let result = await response.json();
      if (result && result.response && result.response.length > 0) {
        if (result.response[0].intents) {
          let data = result.response[0].intents;
          if (flag) return await fetchIntentsResponse(data, shippingId);
          setIntentName(data['intent_name']);
          setCurrentFlow(data['type']);
          setIntentTicket(data['intent_ticket']);
          return [];
        } else if (
          statusButtons.includes(currentFlow) ||
          HOME_BUTTONS.includes(currentButtonValue) ||
          MIS_DAM_PRODUCT === currentButtonValue
        ) {
          return await fetchIntentsFromStorage(shippingId);
        }
        return [];
      }
      return [];
    } else {
      // console.log('intents data received', currentButtonValue);
      return await fetchIntentsFromStorage(shippingId);
    }
  } catch (error) {
    console.error('Error sending fetchIntentOfInput:', error);
    return [];
  }
};

export async function getFutureDeliveryDates(response) {
  try {
    let today = new Date();
    for (let i = 1; i <= 7; i++) {
      let date = new Date(today);
      date.setDate(today.getDate() + i);
      date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      response.push({ type: 'date', content: date });
    }
    return response;
  } catch (error) {
    console.log('error in getFutureDeliveryDates');
    return [];
  }
}

export const createUpdateTicket = async (updatedValue, ticket) => {
  try {
    // #enhancements_pxb5559 changes done according workflow enhancements
    let ticketDetails = await getTicketDetails();
    if (
      ticketDetails &&
      ticketDetails.length > 0 &&
      ticketDetails[0] === 'Closed'
    ) {
      await createStatusTicket(getAWBValue(), 'closed-ticket');
      let msgResponse = ticketDetails[1];
      if (msgResponse && msgResponse !== '') {
        return msgResponse;
      }
      return [];
    }
    let ticketPayload = await createTicketPayload(
      getAWBValue(),
      ticket,
      getIntentName(),
      updatedValue
    );
    const response = await fetch(
      ChatBotApiUrl,
      await fetchHeaders(ticketPayload)
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (
      getIntentName() == 'update-delivery-date' ||
      getIntentName() == 'update-pickup-date'
    ) {
      requestInsertionToDB('write', 'update', '', updatedValue);
    } else {
      requestInsertionToDB('write', 'update', updatedValue, '');
    }
    let ticketData = await dataReceived(result);
    // #enhancements_pxb5559 changes done according workflow enhancements
    if (
      getCurrentFlow() === FUTURE_DATE_DELIVERY &&
      ticketData.includes('previous status is reattempt')
    ) {
      setIntentValue('24');
      createStatusTicket(getAWBValue(), 'closed-ticket');
      let msgResponse = ticketDetails[1];
      if (msgResponse && msgResponse !== '') {
        return msgResponse;
      }
    }
    return ticketData;
  } catch (error) {
    console.log('error in createStatusTicket', error);
    return [];
  }
};

export const createStatusTicket = async (shipmentId, ticket) => {
  try {
    // #enhancements_pxb5559 changes done according workflow enhancements
    let isPddBreach = getPddBreach();
    if (isPddBreach === 'true') {
      return createComplintTicket(getAWBValue(), '');
    }
    let ticketPayload = await createTicketPayload(
      shipmentId,
      ticket,
      'ticket_data',
      getIntentValue()
    );
    const response = await fetch(
      ChatBotApiUrl,
      await fetchHeaders(ticketPayload)
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    requestInsertionToDB('read', 'status', '', '');
    return [];
  } catch (error) {
    console.log('error in createStatusTicket', error);
    return [];
  }
};

async function updateIntentValues() {
  try {
    let currentFlow = getCurrentFlow();
    let flag = false;
    // #enhancements_pxb5559 changes done according workflow enhancements
    if (currentFlow === WRONG_PRODUCT_PICKED) {
      setIntentValue('56');
      flag = true;
    } else if (
      currentFlow === PRODUCT_NOT_PICKED &&
      getButtonName() !== 'No, the shipment is not yet picked.' &&
      getButtonName() !== 'Ok, Kindly pick the shipment quickly.' &&
      getButtonName() !==
        'I do not agree with the reason for non-pickup provided.'
    ) {
      setIntentValue('90');
      flag = true;
    } else if (currentFlow === MISBEHAVIOR_BY_SR) {
      flag = false;
      setIntentValue('21');
    } else if (currentFlow === CHARGED_EXTRA_AMOUNT) {
      flag = false;
      setIntentValue('19');
    } else {
      flag = false;
    }
    return flag ? 'ticket_data' : 'complaint-raise';
  } catch (error) {
    console.log('error in updateIntentValues', error);
  }
}

export const createComplintTicket = async (shipmentId, ticket) => {
  try {
    const name_extra = await updateIntentValues();
    // #enhancements_pxb5559 changes done according workflow enhancements
    let ticketDetails = await getTicketDetails();
    let buttonName = getButtonName();
    let currentFlow = getCurrentFlow();
    let delayFlag = false;
    if (
      ticketDetails &&
      ticketDetails.length > 0 &&
      ticketDetails[0] === 'Closed'
    ) {
      ticket = 'closed-ticket';
    } else if (getIntentValue() === '139' || getIntentValue() === '131') {
      delayFlag = true;
    }
    let ticketPayload = await createTicketPayload(
      shipmentId,
      ticket,
      name_extra,
      getIntentValue()
    );
    if (delayFlag) {
      ticketPayload = await createDelayPayload(
        shipmentId,
        'update-delay-date-yes',
        'update-delay-date'
      );
    }
    const response = await fetch(
      ChatBotApiUrl,
      await fetchHeaders(ticketPayload)
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    requestInsertionToDB('write', 'complaint', '', '');
    let ticketData = await dataReceived(result);
    // #enhancements_pxb5559 changes done according workflow enhancements
    if (
      ticketDetails &&
      ticketDetails.length > 0 &&
      ticket === 'closed-ticket'
    ) {
      let msgResponse = ticketDetails[1];
      if (msgResponse && msgResponse !== '') {
        return msgResponse;
      }
    } else if (
      ticketData &&
      !(ticketData.includes('Sorry') || ticketData.includes('Open ticket'))
    ) {
      if (
        buttonName ===
          'I do not agree with the reason for non-pickup provided.' ||
        buttonName === 'Ok, Kindly pick the shipment quickly.'
      ) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `Your complaint has been registered (Ticket #${ticketNum}), and our team will contact you with updates.`;
        return ticketNum !== '' ? prompt : ticketData;
      } else if (
        buttonName === 'No, I have not received my shipment' ||
        buttonName === 'No, the shipment is not yet picked.'
      ) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `We apologize for the inconvenience! Let us look into this on priority. Your complaint has been registered (Ticket #${ticketNum}), and our team will contact you with updates.`;
        return ticketNum !== '' ? prompt : ticketData;
      } else if (currentFlow === SHIPMENT_NOT_RECEIVED) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `Your complaint has been registered (Ticket #${ticketNum}), and our team will contact you shortly with updates. Thank you for your patience and understanding!`;
        if (
          ticketDetails &&
          ticketDetails.length > 0 &&
          ticketDetails[1] !== ''
        ) {
          let msgResponse = ticketDetails[1];
          return ticketNum !== '' ? `${msgResponse}\n${prompt}` : msgResponse;
        }
        return ticketNum !== '' ? prompt : ticketData;
      } else if (currentFlow === MISBEHAVIOR_BY_SR) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `We're sorry to hear about your experience. Your complaint regarding misbehavior by the service representative has been registered successfully. Your ticket number is #${ticketNum}. Our team will investigate the matter and get in touch with you shortly with updates. Thank you for bringing this to our attention!`;
        return ticketNum !== '' ? prompt : ticketData;
      } else if (currentFlow === CHARGED_EXTRA_AMOUNT) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `We apologize for the inconvenience. Your complaint regarding the extra amount charged by the service representative has been successfully registered. Your ticket number is #${ticketNum}. Our team will investigate the matter and reach out to you shortly with updates. Thank you for bringing this to our attention!`;
        return ticketNum !== '' ? prompt : ticketData;
      } else if (currentFlow === WRONG_PRODUCT_PICKED) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `We apologize for the inconvenience. Your complaint regarding the wrong product being picked by the service representative has been successfully registered. Your ticket number is #${ticketNum}. Our team will investigate the matter and contact you with updates shortly.`;
        return ticketNum !== '' ? prompt : ticketData;
      } else if (currentFlow === PRODUCT_DAMAGED) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `We apologize for the inconvenience. Your complaint regarding the product being damaged has been successfully registered. Your ticket number is #${ticketNum}. Our team will investigate the matter and contact you with updates shortly. Thank you for bringing this to our attention!`;
        return ticketNum !== '' ? prompt : ticketData;
      } else if (currentFlow === PRODUCT_MISSING) {
        let ticketNum = ticketData.replace(/\D/g, '');
        let prompt = `We apologize for the inconvenience. Your complaint regarding the product being missing has been successfully registered. Your ticket number is #${ticketNum}. Our team will investigate the matter and contact you with updates shortly. Thank you for bringing this to our attention!`;
        return ticketNum !== '' ? prompt : ticketData;
      }
    }
    return ticketData;
  } catch (error) {
    console.log('error in createStatusTicket', error);
    return [];
  }
};

export const requestInsertionToDB = async (
  operation,
  type,
  updatedNo,
  updatedDate
) => {
  try {
    const payload = await createRequestPayload(
      getNumber(),
      operation,
      getIntentTicket(),
      getAWBValue(),
      getCurrentFlow(),
      updatedNo,
      updatedDate,
      type
    );
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    // console.log('result -------->>>', result)
  } catch (error) {
    console.log('error in requestInsertionToDB', error);
    return;
  }
};

// #features_pxb5559 changes done for future pickup and auto sent OTP
export const validateShippingID = async (shippingId) => {
  try {
    let shippingID = shippingId ? shippingId : getAWBValue();
    const payload = await createAwbDetailsPayload(shippingID);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (result && result.response && result.response.length > 0) {
      if (result.response[0].text) {
        let data = result.response[0].text;
        if (data !== 'None') {
          setRegisteredNo(data);
          return data;
        }
      }
      return false;
    }
    return false;
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return false;
  }
};

// #enhancements_pxb5559 changes done according workflow enhancements
export const getShipmentTypeFromInput = async (shippingId) => {
  try {
    let shippingID = shippingId ? shippingId : getAWBValue();
    let currentFlow = getCurrentFlow();
    if (currentFlow === '') {
      currentFlow = getButtonName();
    }
    let isAWBChar = shippingID[0];
    const isFWD = isAWBChar === '1' && fwdButtons.includes(currentFlow);
    const isRVP = isAWBChar === '2' && rvpButtons.includes(currentFlow);
    if (isFWD || isRVP) {
      return 'true';
    } else if (
      !(fwdButtons.includes(currentFlow) || rvpButtons.includes(currentFlow))
    ) {
      return 'true';
    }
    return isAWBChar === '1'
      ? 'Kindly provide a valid shipment ID'
      : currentFlow === PRODUCT_DAMAGED || currentFlow === PRODUCT_MISSING
      ? 'Kindly provide a valid delivery shipment ID'
      : 'Kindly provide a valid shipment ID for which you are expecting delivery';
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return 'false';
  }
};

export const getTicketDetails = async () => {
  try {
    let shippingID = getAWBValue();
    let currentFlow = getCurrentFlow();
    let currentButton = getButtonName();
    let shipmentStatus = getShipmentStatus();
    let attempts = getAttemptCount();
    let ticketType = 'Closed';
    let isPddBreach = getPddBreach();
    let eddDate = getEDD();
    let prompt = '';
    let delivertDate = getDDT();
    // #enhancements_pxb5559 changes done according workflow enhancements
    if (delivertDate && delivertDate != '') {
      delivertDate = delivertDate.split(' ')[0];
    }
    let clientName = getClientName();
    let shipmentType = getShipmentType();
    let reattemptDate = getReattemptDate();
    let ndrReason = getNDR();
    let ndrDate = getNDRDate();
    if (currentFlow === DELIVERY_DATE_ENQUIRY) {
      if (isPddBreach === 'true' && shipmentStatus === 'OFD') {
        setIntentValue('139');
      } else if (
        isPddBreach === 'true' &&
        attempts === 0 &&
        shipmentStatus === 'Inscan'
      ) {
        ticketType = 'Open';
        setIntentValue('139');
      }
    } else if (currentFlow === FUTURE_DATE_DELIVERY) {
      if (shipmentStatus === 'Inscan' && attempts >= 1) {
        ticketType = 'Open';
        prompt =
          'Shipment already in reattempt. It is not eligible for future delivery date change.';
      } else if (
        shipmentStatus === 'Manifestation' ||
        shipmentStatus === 'Inscan' ||
        shipmentStatus === 'OFD'
      ) {
        setIntentValue('24');
        prompt =
          "The shipment has not yet been attempted for delivery, and as such, I won't be able to update the delivery date at this time. Thank you for your understanding!";
      } else if (shipmentStatus === 'Delivered') {
        setIntentValue('24');
        prompt = `The shipment was successfully delivered on ${delivertDate}. It is not eligible for a future delivery date change.`;
      } else if (shipmentStatus === 'RTO') {
        setIntentValue('24');
        prompt = `The shipment with ID ${shippingID} is a prepaid order placed through ${clientName}. As per the instructions from the client, the shipment will be returned to its origin. For any queries or further details, please contact the client directly`;
      }
    } else if (currentFlow === UPDATE_ALTERNATE_CONTACT) {
      setIntentValue('98');
      if (shipmentStatus === 'Delivered' && getBusinessType() === 'forward') {
        prompt =
          'Your shipment has already been delivered, so the mobile number cannot be updated at this stage. Thank you for your understanding!';
      } else if (
        (shipmentStatus === 'Inscan' || shipmentStatus === 'PickedUp') &&
        getBusinessType() === 'reverse'
      ) {
        prompt =
          'Your shipment has already been picked, so the mobile number cannot be updated at this stage. Thank you for your understanding!';
      } else {
        ticketType = 'Open';
      }
    } else if (currentFlow === FUTURE_PICKUP_DATE) {
      if (shipmentStatus === 'Manifestation' && attempts >= 1) {
        ticketType = 'Open';
        prompt =
          'Shipment already in reattempt. It is not eligible for pickup date change.';
      } else if (
        shipmentStatus === 'Manifestation' ||
        shipmentStatus === 'Inscan' ||
        shipmentStatus === 'OFP'
      ) {
        setIntentValue('59');
        prompt =
          "The shipment has not yet been attempted for pickup, and as such, I won't be able to update the pickup date at this time. Thank you for your understanding!";
      } else if (shipmentStatus === 'PickedUp') {
        setIntentValue('59');
        prompt = `The shipment has been already picked up on ${delivertDate}. It is not eligible for a future pickup date change.`;
      } else if (shipmentStatus === 'DTO' && getWHHandOver() === 'true') {
        setIntentValue('59');
        prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName}. We have successfully picked up the product on ${delivertDate}, and it is delivered to its origin. For any queries, please feel free to contact ${clientName} directly. Thank you for your understanding!`;
      } else if (shipmentStatus === 'DTO') {
        setIntentValue('59');
        prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName}. We have successfully picked up the product on ${delivertDate}, and it will be delivered to its origin very soon. For any queries, please feel free to contact ${clientName} directly. Thank you for your understanding!`;
      }
    } else if (currentFlow === SHIPMENT_NOT_RECEIVED) {
      if (
        currentButton ===
          'I do not agree with the reason of non delivery provided.' ||
        currentButton === 'Ok, deliver the shipment quickly.'
      ) {
        return ['Open', ''];
      } else if (currentButton === 'Yes, I have received my shipment') {
        return [ticketType, 'Great!'];
      } else if (currentButton === 'No, I have not received my shipment') {
        ticketType = 'Open';
        prompt =
          'We apologize for the inconvenience! Let us look into this on priority.';
      } else if (
        shipmentStatus === 'Manifestation' ||
        shipmentStatus === 'Inscan'
      ) {
        setIntentValue('108');
        if (
          eddDate &&
          eddDate !== '' &&
          eddDate !== 'null' &&
          eddDate !== 'None'
        ) {
          setIntentValue('1');
        }
        if (shipmentStatus === 'Manifestation') {
          prompt = `We hear you and truly understand your concern. As your shipment is yet to be picked up from the vendor or warehouse, we are currently unable to register a complaint. Please rest assured that we're keeping track of it, and you can check back later for an updated status. Thank you for your patience and understanding!`;
        } else if (
          shipmentStatus === 'Inscan' &&
          eddDate &&
          isPddBreach === 'false' &&
          attempts === 0
        ) {
          setIntentValue('1');
          prompt = `We hear you! Your shipment is on track for delivery within the estimated timeframe. Since the expected delivery date hasn't passed yet, a complaint cannot be registered at this time. Rest assured, we're working to get your order to you as scheduled. Let us know if you need any further assistance!`;
        } else if (
          isPddBreach === 'true' &&
          shipmentStatus === 'Inscan' &&
          attempts === 0
        ) {
          ticketType = 'Open';
          setIntentValue('139');
        } else if (shipmentStatus === 'Inscan' && attempts >= 1) {
          if (reattemptDate && reattemptDate != '' && reattemptDate != 'None') {
            prompt = `The shipment with ID ${shippingID} is a ${shipmentType} order placed through ${clientName}. We were unable to deliver it due to ${ndrReason} on ${ndrDate}. We will reattempt delivery on ${reattemptDate}.`;
          } else {
            prompt = `The shipment with ID ${shippingID} is a ${shipmentType} order placed through ${clientName}. We were unable to deliver it due to ${ndrReason} on ${ndrDate}. We are currently awaiting further instructions from the client. Once we receive the updated delivery date, we will process the request and ensure the shipment is delivered as soon as possible.`;
          }
        }
      } else if (shipmentStatus === 'OFD') {
        setIntentValue('1');
        if (isPddBreach === 'true') {
          setIntentValue('139');
          prompt = `The shipment is out for delivery today. Our service representative will contact you shortly to ensure smooth delivery. Thank you for your understanding and patience!`;
        } else {
          prompt = `We hear you! Your shipment is on track for delivery within the estimated timeframe. Since the expected delivery date hasn't passed yet, a complaint cannot be registered at this time. Good news! It is out for delivery today. Our service representative will contact you shortly to ensure smooth delivery. Thank you for your understanding and patience!`;
        }
      } else if (shipmentStatus === 'Delivered') {
        prompt = `The shipment with ID ${shippingID} is a ${shipmentType} order placed through ${clientName}. We are pleased to inform you that it has been successfully delivered on ${delivertDate}.`;
      } else if (shipmentStatus === 'RTO' && getWHHandOver() === 'true') {
        setIntentValue('107');
        prompt = `We understand your concern and truly appreciate you reaching out. As per our records, this shipment was marked as RTO (Return to Origin) and has been returned to the vendor, so we're unable to register a complaint in this case. If you need any further assistance or have any questions, please don't hesitate to reach out. We're here to help!`;
      } else if (shipmentStatus === 'RTO') {
        setIntentValue('2');
        prompt = `We understand your concern and truly appreciate you reaching out. As per our records, this shipment was marked as RTO (Return to Origin), so we're unable to register a complaint in this case. If you need any further assistance or have any questions, please don't hesitate to reach out. We're here to help!`;
      }
    } else if (currentFlow === MISBEHAVIOR_BY_SR) {
      if (
        (shipmentStatus === 'Manifestation' || shipmentStatus === 'Inscan') &&
        attempts === 0 &&
        getBusinessType() === 'forward'
      ) {
        prompt =
          "It looks like your product hasn't been attempted yet. If you have any other concerns, feel free to let us know!";
      } else if (
        shipmentStatus === 'Manifestation' &&
        attempts === 0 &&
        getBusinessType() === 'reverse'
      ) {
        prompt =
          "It looks like your product hasn't been pickedup yet. If you have any other concerns, feel free to let us know!";
      } else {
        ticketType = 'Open';
      }
    } else if (currentFlow === CHARGED_EXTRA_AMOUNT) {
      if (shipmentStatus === 'Delivered') {
        ticketType = 'Open';
      } else if (shipmentStatus === 'RTO') {
        prompt = `We understand your concern. However, as per our records, this shipment was marked as RTO (Return to Origin). Unfortunately, we are unable to raise a complaint in this case. If you have any further questions, please feel free to reach out. We're here to help!`;
      } else {
        prompt =
          'The shipment is not delivered yet. If you have any other concerns, feel free to let us know!';
      }
    } else if (currentFlow === WRONG_PRODUCT_PICKED) {
      if (shipmentStatus === 'Manifestation') {
        prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName} on ${delivertDate}. As per our details, the product has not yet been picked. Please contact our Customer Care at 020-67664200 for further assistance. Thank you for your understanding!`;
        // "It looks like your product hasn't been picked yet. If you have any other concerns, feel free to let us know!";
      } else if (
        shipmentStatus === 'PickedUp' ||
        shipmentStatus === 'DTO' ||
        shipmentStatus === 'OFP'
      ) {
        ticketType = 'Open';
      }
    } else if (currentFlow === PRODUCT_NOT_PICKED) {
      if (
        currentButton === 'Ok, Kindly pick the shipment quickly.' ||
        currentButton ===
          'I do not agree with the reason for non-pickup provided.'
      ) {
        return ['Open', ''];
      } else if (currentButton === 'Yes, my return shipment is picked.') {
        setIntentValue('111');
        return [ticketType, 'Great!'];
      } else if (currentButton === 'No, the shipment is not yet picked.') {
        ticketType = 'Open';
        prompt =
          'We apologize for the inconvenience! Let us look into this on priority.';
      } else if (currentButton === 'No, the shipment is not yet picked.') {
        ticketType = 'Open';
      } else if (shipmentStatus === 'Manifestation' && attempts >= 1) {
        if (reattemptDate && reattemptDate != '' && reattemptDate != 'None') {
          prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName} on ${delivertDate}. We attempted to pick up the product but were unable to proceed due to ${ndrReason} on ${ndrDate}. We will try to pick up the product on ${reattemptDate}. You will be notified via SMS and a phone call once the pickup is scheduled`;
        } else {
          prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName} on ${delivertDate}. We attempted to pick up the product but were unable to proceed due to ${ndrReason} on ${ndrDate}. We are currently awaiting further instructions from the client. Once we receive the updated pickup date, we will process the request and ensure the product is picked up as soon as possible.`;
        }
      } else if (shipmentStatus === 'OFP') {
        setIntentValue('108');
        prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName} on ${delivertDate}. Our service representative is currently out for pickup and will contact you shortly. Thank you for your patience!`;
      } else if (shipmentStatus === 'Manifestation') {
        setIntentValue('108');
        prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName} on ${delivertDate}. We will attempt to pick up the product on ${delivertDate}. Thank you for your patience!`;
      } else if (shipmentStatus === 'PickedUp') {
        setIntentValue('111');
        prompt = `The pickup request for Shipment ID ${shippingID} was initiated by ${clientName}. We successfully picked up the product on ${delivertDate}, and it will be returned to its origin very soon.`;
      } else if (shipmentStatus === 'DTO' && getWHHandOver() === 'true') {
        setIntentValue('111');
        prompt = `We understand your concern. However, as per our records, the product has already been picked up on ${delivertDate} and is delivered to origin. As a result, a complaint cannot be raised in this case. For any queries, please feel free to contact ${clientName} directly. Thank you for your understanding!`;
      } else if (shipmentStatus === 'DTO') {
        setIntentValue('111');
        prompt = `We understand your concern. However, as per our records, the product has already been picked up on ${delivertDate} and is on its way to its origin. As a result, a complaint cannot be raised in this case. For any queries, please feel free to contact ${clientName} directly. Thank you for your understanding!`;
      }
    } else if (
      currentFlow === PRODUCT_DAMAGED ||
      currentFlow === PRODUCT_MISSING
    ) {
      if (currentButton === PRODUCT_DAMAGED && shipmentStatus === 'Delivered') {
        ticketType = 'Open';
        setIntentValue('7');
      } else if (
        currentButton === PRODUCT_MISSING &&
        shipmentStatus === 'Delivered'
      ) {
        ticketType = 'Open';
        setIntentValue('6');
      } else if (shipmentStatus !== 'Delivered' && shipmentStatus !== 'RTO') {
        setIntentValue('108');
        prompt =
          'We noticed that your product has not yet been delivered. Once the delivery is complete, please feel free to raise a complaint if you face any issues. Thank you for your understanding!';
      } else if (shipmentStatus === 'RTO' && getWHHandOver() === 'true') {
        prompt = `We understand your concern and truly appreciate you reaching out. As per our records, this shipment was marked as RTO (Return to Origin) and has been returned to the vendor, so we're unable to register a complaint in this case. If you need any further assistance or have any questions, please don't hesitate to reach out. We're here to help!`;
      } else if (shipmentStatus === 'RTO') {
        prompt = `We understand your concern and truly appreciate you reaching out. As per our records, this shipment was marked as RTO (Return to Origin), so we're unable to register a complaint in this case. If you need any further assistance or have any questions, please don't hesitate to reach out. We're here to help!`;
      }
    }
    return [ticketType, prompt];
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
};
