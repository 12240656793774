import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHeaderTitle from '../EnquireForm/FormHeaderTitle/FormHeaderTitle';
import {
  ButtonWrapper,
  ContainerWrapper,
  ErrorMsgStyled,
  FormContainer,
} from './FranchiseForm.styled';
import { useTranslation } from '../../hooks/useTranslation';
import Button from '../EnquireForm/Button/Button';
import { formConfig, formConstant, regex } from './config';
import Dropdown from '../EnquireForm/Dropdown/Dropdown';
import CustomInput from '../EnquireForm/InputComp/InputText';
import FormBottomSVG from '../EnquireForm/FormBottomSVG/FormBottomSVG';
import pincodes from '../../utils/pincodes.json';
const FranchiseForm = ({ franchiseSelectedOption }) => {
  const t = useTranslation();
  const { inputs } = formConfig(t);
  const initialFormState = {
    fullName: '',
    phoneNumber: '',
    email: '',
    city: '',
    state: '',
    pinCode: '',
    address: '',
    zone: '',
  };
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({
    fullNameError: '',
    phoneNumberError: '',
    emailError: '',
    cityError: '',
    stateError: '',
    addressError: '',
    pinCodeError: '',
    zoneError: '',
  });

  // Handle change for all input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    let normalizedValue = value
      ?.trimStart()
      ?.replace(regex.REMOVE_WHITE_SPACE, ' ');
    if (name == formConstant.EMAIL_ADDRESS) {
      let emailValue = value?.trim();
      setFormData((prevState) => ({
        ...prevState,
        [name]: emailValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: normalizedValue,
      }));
    }
    // Validate the field on change
    validate(name, normalizedValue);
  };

  // Validation implement
  const validate = (name, value) => {
    const newErrors = { ...errors };
    const field = inputs.find((input) => input.name === name);

    if (field) {
      const validations = field.validations;

      // Check required validation
      if (validations.required && !value) {
        newErrors[`${name}Error`] = `${field.placeholder} ${t(
          'app.IS_REQUIRED'
        )}`;
      }
      // Check pattern validation (for email, phone number, etc.)
      else if (validations.pattern && !validations.pattern.test(value)) {
        newErrors[`${name}Error`] = `${t('app.INVALID')} ${field.placeholder}`;
      }
      // Filter invalid pin codes
      else if (name === formConstant.PIN_CODE) {
        const pinCodes = value.split(',').map((pin) => pin.trim());
        const invalidPinCodes = pinCodes.filter(
          (pin) => !pincodes.availablePinCodes.includes(pin)
        );
        if (invalidPinCodes.length > 0) {
          newErrors[`${name}Error`] = `${invalidPinCodes.join(', ')} ${t(
            'app.NOT_SERVICEABLE'
          )}`;
        } else {
          newErrors[`${name}Error`] = '';
        }
      }
      // Check minLength validation
      else if (validations.minLength && value.length < validations.minLength) {
        newErrors[`${name}Error`] = `${field.placeholder} ${t(
          'app.MUST_BE_AT_LEAST_CHARACTERS_LONG',
          { value: validations.minLength }
        )}`;
      }
      // Validate dropdown (industry)
      else if (
        name === formConstant.INDUSTRY &&
        validations.required &&
        !value
      ) {
        newErrors[`${name}Error`] = `${
          ('app.PLEASE_SELECT_A', { value: field.placeholder })
        }`;
      }
      // Validate radio button (typeOfBusiness)
      else if (
        name === formConstant.TYPE_OF_BUSINESS &&
        validations.required &&
        !value
      ) {
        newErrors[`${name}Error`] = `${
          ('app.PLEASE_SELECT_A', { value: field.placeholder })
        }`;
      } else {
        newErrors[`${name}Error`] = '';
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = {};

    // Validate all fields
    inputs.forEach((input) => {
      const value = formData[input.name];
      const validationResult = validate(input.name, value);
      if (validationResult[`${input.name}Error`]) {
        newErrors[`${input.name}Error`] =
          validationResult[`${input.name}Error`];
        formIsValid = false;
      }
    });
    setErrors(newErrors);

    if (!formIsValid) {
      toast.error(t('app.PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_CORRECTLY'));
      return;
    }
    toast.success(t('app.FORM_SUBMITTED_SUCCESSFULLY'));
    setFormData(initialFormState);
  };

  return (
    <ContainerWrapper>
      <div className='headerContainer'>
        <FormHeaderTitle
          headerLeftTitle={t('app.PLEASE_SHARE_THE_NECESSARY_DETAILS')}
          subHeaderTitle={
            franchiseSelectedOption == 'courier_booking'
              ? t('app.OPTION-FIRST')
              : t('app.OPTION-SECOND')
          }
        />
      </div>
      <FormContainer>
        <div className='inputFlexStyled'>
          {inputs?.map((value, index) => {
            if (value.type === 'dropdown') {
              return (
                <div key={value.name} className={value.customStyle}>
                  <Dropdown
                    name={value.name}
                    value={formData[value.name]}
                    options={value.options}
                    onChange={handleChange}
                    placeholder={value.placeholder}
                  />
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            } else {
              return (
                <div key={value.name} className={value.customStyle}>
                  <CustomInput
                    type={value.type}
                    placeholder={value.placeholder}
                    value={formData[value.name]}
                    name={value.name}
                    id={value.name}
                    onChange={handleChange}
                  />
                  {errors[`${value.name}Error`] && (
                    <ErrorMsgStyled>
                      {' '}
                      {errors[`${value.name}Error`]}
                    </ErrorMsgStyled>
                  )}
                </div>
              );
            }
          })}
        </div>
      </FormContainer>

      <ButtonWrapper>
        <Button handleSubmit={handleSubmit} title={t('app.SUBMIT_BUTTON')} />
      </ButtonWrapper>
      <FormBottomSVG />
    </ContainerWrapper>
  );
};

export default FranchiseForm;
