export const industriesList = [
  { value: 'E-commerce', label: 'E-commerce' },
  { value: 'Retail', label: 'Retail' },
  { value: 'FMCG', label: 'FMCG' },
  {
    value: 'Healthcare and Pharmaceuticals',
    label: 'Healthcare and Pharmaceuticals',
  },
  { value: 'Fashion Apparel', label: 'Fashion Apparel' },
  { value: 'FMCD', label: 'FMCD' },
  { value: 'Automobile and Spare Parts', label: 'Automobile and Spare Parts' },
  { value: 'Consumer Electronics', label: 'Consumer Electronics' },
  { value: 'Steel', label: 'Steel' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Textiles', label: 'Textiles' },
  { value: 'Others', label: 'Others' },
];

// export const unitList = [
//     { unit: "meter", value: 1 },
//     { unit: "kilogram", value: 1000 },
//     { unit: "cubic meter", value: 1 },
//   ];
