export const getIntentName = () => sessionStorage.getItem('intent-name') || '';

export const setIntentName = (newName) => {
  sessionStorage.setItem('intent-name', newName);
};

export const getButtonName = () => sessionStorage.getItem('button-name') || '';

export const setButtonName = (newName) => {
  sessionStorage.setItem('button-name', newName);
};

export const getIntentTicket = () =>
  sessionStorage.getItem('intent-ticket') || '';

export const setIntentTicket = (newName) => {
  sessionStorage.setItem('intent-ticket', newName);
};

export const getCurrentFlow = () =>
  sessionStorage.getItem('current-flow') || '';

export const setCurrentFlow = (newName) => {
  sessionStorage.setItem('current-flow', newName);
};

export const getNumber = () => sessionStorage.getItem('mobileno') || '';

export const setNumber = (newNo) => {
  sessionStorage.setItem('mobileno', newNo);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const getIntentValue = () =>
  sessionStorage.getItem('intent-value') || '';

export const setIntentValue = (newName) => {
  sessionStorage.setItem('intent-value', newName);
};

export const getAWBValue = () => sessionStorage.getItem('awb') || '';

export const setAWBValue = (newName) => {
  sessionStorage.setItem('awb', newName);
};

export const getIsOTPValidated = () => sessionStorage.getItem('valid') || '';

export const setIsOTPValidated = (newName) => {
  sessionStorage.setItem('valid', newName);
};

export const clearSelectedKeys = (keys) => {
  keys.forEach((key) => {
    sessionStorage.removeItem(key);
  });
};

export const getIsOtpSent = () => sessionStorage.getItem('otpSent') || false;

export const setIsOtpSent = (newValue) => {
  sessionStorage.setItem('otpSent', newValue);
};

// #features_pxb5559 changes done to store registeredNo
export const getRegisteredNo = () =>
  sessionStorage.getItem('registeredNo') || false;

export const setRegisteredNo = (newValue) => {
  sessionStorage.setItem('registeredNo', newValue);
};

export const getShipmentStatus = () =>
  sessionStorage.getItem('shipmentStatus') || false;

export const setShipmentStatus = (newValue) => {
  sessionStorage.setItem('shipmentStatus', newValue);
};

export const getAttemptCount = () =>
  sessionStorage.getItem('attemptCount') || 0;

export const setAttemptCount = (newValue) => {
  sessionStorage.setItem('attemptCount', newValue);
};

export const getEDD = () => sessionStorage.getItem('edd') || '';

export const setEDD = (newValue) => {
  sessionStorage.setItem('edd', newValue);
};

export const getShipmentType = () =>
  sessionStorage.getItem('shipmentType') || false;

export const setShipmentType = (newValue) => {
  sessionStorage.setItem('shipmentType', newValue);
};

export const getBusinessType = () =>
  sessionStorage.getItem('businessType') || false;

export const setBusinessType = (newValue) => {
  sessionStorage.setItem('businessType', newValue);
};

export const getPddBreach = () =>
  sessionStorage.getItem('pddBreach') || 'false';

export const setPddBreach = (newValue) => {
  sessionStorage.setItem('pddBreach', newValue);
};

export const getDDT = () => sessionStorage.getItem('deliveryDate') || '';

export const setDDT = (newValue) => {
  sessionStorage.setItem('deliveryDate', newValue);
};

export const getClientName = () => sessionStorage.getItem('clientName') || '';

export const setClientName = (newValue) => {
  sessionStorage.setItem('clientName', newValue);
};

export const getReattemptDate = () =>
  sessionStorage.getItem('reattemptDate') || '';

export const setReattemptDate = (newValue) => {
  sessionStorage.setItem('reattemptDate', newValue);
};

export const getNDR = () => sessionStorage.getItem('ndrReason') || '';

export const setNDR = (newValue) => {
  sessionStorage.setItem('ndrReason', newValue);
};

export const getNDRDate = () => sessionStorage.getItem('ndrDate') || '';

export const setNDRDate = (newValue) => {
  sessionStorage.setItem('ndrDate', newValue);
};
// #enhancements_pxb5559 changes done according workflow enhancements
export const getWHHandOver = () =>
  sessionStorage.getItem('WHHandOver') || 'false';

export const setWHHandOver = (newValue) => {
  sessionStorage.setItem('WHHandOver', newValue);
};
