const RETURN_REQUEST_ENQUIRY = 'I want to enquire about my return request.';
const DELIVERY_DATE_ENQUIRY =
  'I want to enquire about the estimated date of delivery of my shipment.';
const FUTURE_DATE_DELIVERY = 'I want future date delivery.';
const FUTURE_PICKUP_DATE = 'I want future pickup date.';
const UPDATE_ALTERNATE_CONTACT =
  'I want to update my alternate contact number.';
const SHIPMENT_NOT_RECEIVED = 'I have not received my shipment yet.';
const MISBEHAVIOR_BY_SR =
  'It is about misbehavior by the service representative.';
const CHARGED_EXTRA_AMOUNT =
  'I have been charged extra amount by the service representative.';
const WRONG_PRODUCT_PICKED =
  'Wrong return product has been picked by service representative.';
const PRODUCT_NOT_PICKED = 'The return product is not yet picked.';
const PRODUCT_DAMAGED = 'The product is damaged';
const PRODUCT_MISSING = 'The product is missing';
const STATUS_ENQUIRY = 'I want to enquire the status.';
const REQUEST_REGISTER = 'I want to register a request.';
const COMPLAINT_REGISTER = 'I want to raise a new complaint.';
const MIS_DAM_PRODUCT = 'The product delivered in damaged/missing.';

export const constants = {
  RETURN_REQUEST_ENQUIRY,
  DELIVERY_DATE_ENQUIRY,
  FUTURE_DATE_DELIVERY,
  FUTURE_PICKUP_DATE,
  UPDATE_ALTERNATE_CONTACT,
  SHIPMENT_NOT_RECEIVED,
  MISBEHAVIOR_BY_SR,
  CHARGED_EXTRA_AMOUNT,
  WRONG_PRODUCT_PICKED,
  PRODUCT_NOT_PICKED,
  PRODUCT_DAMAGED,
  PRODUCT_MISSING,
  prompt_of_additional_options: 'Additional options',
  prompt_of_complaint_confirmation: 'Complaint confirmation',
  status_buttons: [RETURN_REQUEST_ENQUIRY, DELIVERY_DATE_ENQUIRY],
  request_buttons: [
    FUTURE_DATE_DELIVERY,
    UPDATE_ALTERNATE_CONTACT,
    FUTURE_PICKUP_DATE,
  ],
  complaint_buttons: [
    SHIPMENT_NOT_RECEIVED,
    MISBEHAVIOR_BY_SR,
    CHARGED_EXTRA_AMOUNT,
    WRONG_PRODUCT_PICKED,
    PRODUCT_NOT_PICKED,
    PRODUCT_DAMAGED,
    PRODUCT_MISSING,
  ],
  prompt_of_otp: 'OTP',
  prompt_of_number: 'Number Prompt',
  prompt_of_fetch_records: 'fetch-records',
  prompt_of_fetch_records_otp: 'fetch-records-otp',
  prompt_of_invalid_number: 'Invalid Number',
  session_keys: [
    'awb',
    'button-name',
    'current-flow',
    'intent-name',
    'intent-ticket',
    'mobileno',
    'valid',
    'intent-value',
    'otpSent',
    'registeredNo',
    'shipmentType',
    'edd',
    'attemptCount',
    'shipmentStatus',
    'businessType',
    'clientName',
    'deliveryDate',
    'pddBreach',
    'ndrDate',
    'ndrReason',
    'reattemptDate',
    'WHHandOver',
  ],
  rvp_flag: ['complaint-raise-wrong-picked', 'complaint-raise-not-picked'],
  additionalButtons: ['Yes please.', 'No, Thanks!'],
  fwd_buttons: [
    SHIPMENT_NOT_RECEIVED,
    CHARGED_EXTRA_AMOUNT,
    FUTURE_DATE_DELIVERY,
    PRODUCT_DAMAGED,
    PRODUCT_MISSING,
  ],
  rvp_buttons: [WRONG_PRODUCT_PICKED, PRODUCT_NOT_PICKED, FUTURE_PICKUP_DATE],
  home_buttons: [REQUEST_REGISTER, COMPLAINT_REGISTER, STATUS_ENQUIRY],
  MIS_DAM_PRODUCT,
};
